import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  notification,
  Row,
  Space,
  Spin,
  Tooltip,
  Upload
} from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
// import save_alt from "../../../../assets/save_alt.png";
import { CloseOutlined } from "@ant-design/icons";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import moment from "moment";
import delete_icon from "../../../../assets/delete.png";
import downloadIcon from "../../../../assets/download.png";
import { UserAuth } from "../../../../context_api/ContextApi";
import API_BASE_URL from "../../../../env";
import CellWithOverflowTooltip from "../../../CellWithOverflowTooltip/CellWithOverflowTooltip";

const Documents = () => {
  const { open, onClose } = UserAuth();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const Token = localStorage.getItem("Token");
  const clinic_id = localStorage.getItem("clinic_id");

  const getFileNameFromUrl = (url) => {
    if (!url) return "No Attachment";
    return url.split("/").pop(); // Get the last part after the "/"
  };

  const [spinner, setSpinner] = useState(false);

  const handleDownload = async (record) => {
    setSpinner(true);
    const { document_id, attachment_ids } = record;
    const url = `${API_BASE_URL}/api/v1/documents/download-documents/${clinic_id}/${document_id}/${attachment_ids}`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        // responseType: "arraybuffer",
      });

      const blob = new Blob([response.data], { type: "image/jpeg" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = record.document_name || "document.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      notification.success({
        message: "Document downloaded successfully",
        placement: "topRight",
        duration: 3,
        style: {
          borderRadius: "10px",
          border: "1px solid #4CAF50",
          background: "#DAFEC5",
          boxShadow: "2px 2px 32px 0px rgba(0, 0, 0, 0.25)",
        },
      });
      setSpinner(false);
    } catch (error) {
      message.error("Failed to download document.");
      console.error(error);
    }
  };

  const showDeleteModal = (record) => {
    setDocumentToDelete(record);
    setIsModalVisible(true);
  };

  const handleDelete = async () => {
    // const { document_id } = documentToDelete;
    const url = `${API_BASE_URL}/api/v1/documents/delete-documents?clinicId=${clinic_id}&documentId=${documentToDelete}`;
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    try {
      const response = await axios.delete(url, config);
      if (response.status === 200) {
        message.success("Document deleted successfully!");
        fetchDocuments();
        setIsModalVisible(false);
      } else {
        message.error("Failed to delete document.");
      }
    } catch (error) {
      message.error("Error deleting document.");
      console.error(error);
    }
  };

  const fetchDocuments = async () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/documents/get-documents?clinicId=${clinic_id}`,
        config
      );
      if (response.data.status_code === 1000) {
        setDocuments(response.data.data);
        setRowData(response.data.data);
      } else {
        message.error("Failed to fetch documents.");
      }
    } catch (error) {
      message.error("Error fetching documents.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [clinic_id, Token]);

  const onFinish = async (values) => {
    setFormLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    try {
      const { documentName, issuedDate, files } = values;
      const formattedDate = moment(issuedDate).format("YYYY-MM-DD");

      const formData = new FormData();
      formData.append("documentName", documentName);
      formData.append("issuedDate", formattedDate);

      files.fileList.forEach((file) => {
        formData.append("file", file.originFileObj);
      });

      const response = await axios.post(
        `${API_BASE_URL}/api/v1/documents/${clinic_id}/upload-documents`,
        formData,
        config
      );

      if (response.status === 200) {
        message.success("Documents uploaded successfully!");
        form.resetFields();
        onClose();
        fetchDocuments();
      }
    } catch (error) {
      message.error("Failed to upload documents. Please try again.");
      console.error(error);
    } finally {
      setFormLoading(false);
    }
  };

  const gridStyle = useMemo(
    () => ({ height: "calc(100% - 68px)", width: "100%" }),
    []
  );
  const gridRef = useRef(null);
  const [rowData, setRowData] = useState([]);

  const columnDefs = [
    {
      field: "document_name",
      headerName: "Document Name",
      cellRenderer: (params) => {
        const { document_name } = params.data;
        return <div className="row-data-element">{document_name}</div>;
      },
    },
    {
      field: "issued_date",
      headerName: "Issued Date",
      cellRenderer: (params) => {
        const { issued_date } = params.data;

        return <div className="row-data-element">{issued_date}</div>;
      },
    },
    {
      field: "document_urls",
      headerName: "Image",
      cellRenderer: (params) => {
        const { document_urls, attachment_ids, document_id } = params.data;
        return (
          <div className="row-data-element">
            <Tooltip
              title={
                <Spin spinning={spinner} tip={"Downloading..."}>
                  {document_urls &&
                    document_urls.map((item, index) => (
                      <div className="tooltip-container">
                        <div className="data-container">
                          <div className="notes-data-row">
                            <span className="attachment-link">
                              <CellWithOverflowTooltip
                                value={getFileNameFromUrl(item)}
                              />
                            </span>
                            <img
                              src={downloadIcon}
                              alt="Download"
                              className="download-icon"
                              onClick={() =>
                                handleDownload({
                                  document_id: document_id,
                                  attachment_ids: attachment_ids[index],
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </Spin>
              }
              color="white"
              overlayInnerStyle={{
                color: "#000",
                padding: "10px",
                borderRadius: "8px",
              }}
              placement="bottom"
              trigger="click"
            >
              <div className="attachments">
                <u style={{ color: "#006FE6", cursor: "pointer" }}>
                  <span>{document_urls?.length}</span>
                </u>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      cellRenderer: (params) => {
        const { document_id } = params.data;
        return (
          <div className="row-data-element">
            <Space size="middle">
              {/* <img
                src={save_alt}
                alt="Download"
                style={{ width: 24, cursor: "pointer" }}
                onClick={() => handleDownload(document_id)} // Trigger download
              /> */}
              {/* {document_id} */}
              <img
                src={delete_icon}
                alt="Delete"
                style={{ width: 24, cursor: "pointer" }}
                onClick={() => showDeleteModal(document_id)} // Trigger delete modal
              />
            </Space>
          </div>
        );
      },
      pinned: "right", // This fixes the column to the right side
      suppressMovable: true, // Prevent this column from being draggable
      lockPosition: true,
      sortable: false, // Optional: Disable sorting if not needed
      filter: false,
      resizable: false,
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 2,
      resizable: true, // Enable column resizing
      autoHeight: true,
      wrapText: true,
    };
  }, []);

  const onFirstDataRendered = (params) => {
    params.api.paginationGoToPage(0); // Optional, but ensures the grid shows the first page
  };

  const rowHeight = 80;

  const gridOptions = {
    suppressHorizontalScroll: false, // Enable horizontal scrolling
    overlayNoRowsTemplate:
      '<span class="custom-no-rows">No notes to display</span>',
  };

  return (
    <div style={{ margin: "12px 0 0 0" }} className="documents-main-div">
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          {!loading && (
            <div style={gridStyle} className="ag-theme-quartz">
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onFirstDataRendered={onFirstDataRendered}
                tooltipShowDelay={0}
                rowHeight={rowHeight}
                gridOptions={gridOptions}
              />
            </div>
          )}
        </>
      )}
      <Drawer open={open} closeIcon={false} destroyOnClose>
        <Spin size="large" spinning={formLoading}>
          <div style={{ height: "90vh" }} className="documents-drawer-main-div">
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              className="h-100 d-flex flex-col jc-between"
            >
              <Row className="d-flex flex-col" style={{ gap: 20 }}>
                <Row className="d-flex jc-end">
                  <CloseOutlined
                    onClick={() => {
                      onClose();
                      form.resetFields();
                    }}
                    style={{ cursor: "pointer", fontSize: 24 }}
                  />
                </Row>
                <Row>
                  <span className="add-holiday-header">Add Document</span>
                </Row>
                <Row className="d-flex flex-col">
                  <span className="input-header">
                    Document Name <span style={{ color: "#F00" }}>*</span>
                  </span>

                  <Form.Item
                    name="documentName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the document name",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Document Name"
                      className="input"
                      style={{ textTransform: "uppercase" }}
                    />
                  </Form.Item>
                </Row>
                <Row className="d-flex flex-col">
                  <span className="input-header">
                    Issued Date <span style={{ color: "#F00" }}>*</span>
                  </span>
                  <Form.Item
                    name="issuedDate"
                    rules={[
                      {
                        required: true,
                        message: "Please select the issued date",
                      },
                    ]}
                  >
                    <DatePicker
                      className="input"
                      style={{ width: "100%" }}
                      placeholder="Select Date"
                      disabledDate={(current) =>
                        current && current > moment().endOf("day")
                      }
                    />
                  </Form.Item>
                </Row>
                <Row className="d-flex flex-col">
                  <span className="input-header">
                    Document Images <span style={{ color: "#F00" }}>*</span>
                  </span>
                  <Form.Item
                    name="files"
                    rules={[
                      {
                        required: true,
                        message: "Please upload the document images",
                      },
                    ]}
                  >
                    <Upload
                      listType="picture"
                      multiple
                      beforeUpload={() => false}
                    >
                      <Button>Browse</Button>
                    </Upload>
                  </Form.Item>
                </Row>
              </Row>
              <Row className="d-flex jc-end">
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </div>
        </Spin>
      </Drawer>

      <Modal
        title="Confirm Deletion"
        visible={isModalVisible}
        onOk={handleDelete}
        onCancel={() => setIsModalVisible(false)}
        okText="Delete"
        centered
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this document?</p>
      </Modal>
    </div>
  );
};

export default Documents;
