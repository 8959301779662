import { Col, message, Row, Table } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import backIcon from "../../../../assets/arrow_back.png";
import appointmentIcon from "../../../../assets/ec_appointment.png";
import benefitIcon from "../../../../assets/ec_benefits.png";
import deductibelIcon from "../../../../assets/ec_deductible.png";
import verifiedIcon from "../../../../assets/ec_verified.png";
import editIcon from "../../../../assets/edit.png";
import toothIcon from "../../../../assets/tooth.png";
import API_BASE_URL from '../../../../env';
import { routes_patient_details } from '../../../../utils/RoutingConsts';
import './EligibilityCheck.css';

const EligibilityCheckDetails = () => {
    const Token = localStorage.getItem('Token');
    const navigate = useNavigate();
    const { patientId, memberId } = useParams();
    const [patient, setPatient] = useState();
    const [eligibilityDetails, setEligibilityDetails] = useState();
    const [treatmentHistory, setTreatmentHistory] = useState();

    const config = {
        headers: {
            Authorization: `Bearer ${Token}`,
        },
    };

    useEffect(() => {
        getPatientData();
        eligibilityCheckDetail();
        getTreatmentHistory();
    }, [patientId, memberId]);

    const eligibilityCheckDetail = () => {
        axios
            .get(`${API_BASE_URL}/api/v1/insurance_eligibility_check/getAllEligibilityDetailsByMemberId?patientMemberId=${memberId}`, config)
            .then((res) => {
                setEligibilityDetails(res.data.data[0]);
            })
            .catch(err => message.error(err.response.data.data.errors))
    }

    const getPatientData = () => {
        axios
            .get(`${API_BASE_URL}/api/v1/patients/fetchPatientsByPatientId?patientId=${patientId}`, config)
            .then((res) => {
                setPatient(res.data)
            })
            .catch(error => console.log(error))
    }

    const getTreatmentHistory = () => {
        axios
            .get(`${API_BASE_URL}/api/v1/treatments/getTreatmentsByPatientId?patientId=${patientId}`, config)
            .then((res) => {
                setTreatmentHistory(res.data);
            })
    }

    const convertDate = (dob) => {
        const date = new Date(dob);
        return date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
        });
    };

    const transformedLimitationsData = eligibilityDetails?.limitationAndMaximum?.reduce((acc, item) => {
        const isIndividual = item.coverageLevel === 'Individual';
        const isFamily = item.coverageLevel === 'Family';
        const planPeriod = item.planPeriod === 'Remaining' ? 'Calendar remaining' : item.planPeriod;

        acc.push({
            serviceType: item.serviceType,
            adaCode: '-', // Map ADA codes here if available
            individualAmount: `$${item.amount}`,
            individualPlanPeriod: planPeriod,
            familyAmount: isFamily ? `$${item.amount}` : 'N/A',
            familyPlanPeriod: isFamily ? planPeriod : 'N/A',
        });

        return acc;
    }, []);

    const transformedDeductibleData = eligibilityDetails?.deductible?.reduce((acc, item) => {
        const isIndividual = item.coverageLevel === 'Individual';
        const isFamily = item.coverageLevel === 'Family';
        const planPeriod = item.planPeriod === 'Remaining' ? 'Calendar remaining' : item.planPeriod;

        acc.push({
            serviceType: item.serviceType,
            adaCode: '-', // Map ADA codes here if available
            individualAmount: `$${item.amount}`,
            individualPlanPeriod: planPeriod,
            familyAmount: isFamily ? `$${item.amount}` : '-',
            familyPlanPeriod: isFamily ? planPeriod : 'N/A',
        });

        return acc;
    }, []);

    const transformedTreatmentHistoryData = treatmentHistory?.reduce((acc, item) => {
        const code = item.code ? `${item.code}` : '-';
        const name = item.codeName ? `${item.codeName}` : '-';

        acc.push({
            serviceCode: item.procedure_code1,
            codeAndName: `${code} ${name}`,
            surface: item.surface_quadrant,
            description: item.codeDescription,
            availedOn: convertDate(item.date)
        });

        return acc;
    }, []);

    const limitationAndMaximumColumns = [
        {
            title: '',
            children: [
                {
                    title: 'SERVICE TYPE',
                    dataIndex: 'serviceType',
                    key: 'serviceType',
                }
            ]
        },
        {
            title: '',
            children: [
                {
                    title: 'ADA CODE',
                    dataIndex: 'adaCode',
                    key: 'adaCode',
                }
            ]
        },
        {
            title: 'INDIVIDUAL',
            children: [
                {
                    title: 'AMOUNT',
                    dataIndex: 'individualAmount',
                    key: 'individualAmount',
                },
                {
                    title: 'PLAN PERIOD',
                    dataIndex: 'individualPlanPeriod',
                    key: 'individualPlanPeriod',
                },
            ],
        },
        {
            title: 'FAMILY',
            children: [
                {
                    title: 'AMOUNT',
                    dataIndex: 'familyAmount',
                    key: 'familyAmount',
                },
                {
                    title: 'PLAN PERIOD',
                    dataIndex: 'familyPlanPeriod',
                    key: 'familyPlanPeriod',
                },
            ],
        },
    ];

    const deductibleColumns = [
        {
            title: '',
            children: [
                {
                    title: 'SERVICE TYPE',
                    dataIndex: 'serviceType',
                    key: 'serviceType',
                }
            ]
        },
        {
            title: '',
            children: [
                {
                    title: 'ADA CODE',
                    dataIndex: 'adaCode',
                    key: 'adaCode',
                }
            ]
        },
        {
            title: 'INDIVIDUAL',
            children: [
                {
                    title: 'AMOUNT',
                    dataIndex: 'individualAmount',
                    key: 'individualAmount',
                },
                {
                    title: 'PLAN PERIOD',
                    dataIndex: 'individualPlanPeriod',
                    key: 'individualPlanPeriod',
                },
            ],
        },
        {
            title: 'FAMILY',
            children: [
                {
                    title: 'AMOUNT',
                    dataIndex: 'familyAmount',
                    key: 'familyAmount',
                },
                {
                    title: 'PLAN PERIOD',
                    dataIndex: 'familyPlanPeriod',
                    key: 'familyPlanPeriod',
                },
            ],
        },
    ];

    const coInsuranceColumns = [
        {
            title: 'SERVICE ID',
            dataIndex: 'code',
            key: 'code',
            render: (text) => text || '-'
        },
        {
            title: 'disclaimers',
            dataIndex: 'message',
            key: 'message',
            render: (text) => text || 'N/A'
        },
        {
            title: 'Percentage',
            dataIndex: 'percent',
            key: 'percent',
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
            render: (text) => text || '-'
        }
    ];

    const treatmentHistoryColumns = [
        {
            title: 'SERVICE ID',
            dataIndex: 'serviceCode',
            key: 'serviceCode',
            render: (text) => text || '-'
        },
        {
            title: 'ada code & name',
            dataIndex: 'codeAndName',
            key: 'codeAndName',
        },
        {
            title: 'surface',
            dataIndex: 'surface',
            key: 'surface',
            render: (text) => text || '-'
        },
        {
            title: 'description',
            dataIndex: 'description',
            key: 'description',
            render: (text) => text || 'N/A'
        },
        {
            title: 'availed on',
            dataIndex: 'availedOn',
            key: 'availedOn'
        }
    ];

    return (
        <div className="eligibility-details-container">
            <Helmet>
                <title>Eligibility Check Details</title>
            </Helmet>


            {eligibilityDetails && (
                <div className='eligibility-details-view'>
                    <div className='eligibility-details-header'>
                        <img src={backIcon} alt='Back' className='back-icon' onClick={() => navigate(`${routes_patient_details}/${patientId}`)} />
                        <div className='eligibility-basic-info'>
                            <Row>
                                <span className='eligibility-id'>{memberId}</span>
                            </Row>
                            <Row>
                                <span className='eligibility-title'>{eligibilityDetails.activeCoverage[0].serviceType}</span>
                            </Row>
                            <Row>
                                <span className='eligibility-date-time'>09 Sep 2024</span>
                            </Row>
                        </div>
                    </div>
                    <div className="eligibility-details-body">
                        <div className="eligibility-check-body-div">
                            <Row className='edit-button-row'>
                                <img src={editIcon} alt='Edit' className='edit-icon' onClick={() => navigate(`${routes_patient_details}/${patientId}/edit-eligibility-check/${memberId}`)} />
                            </Row>
                            <Row className="card-container">
                                {/* Dental Coverage Card */}
                                <Col>
                                    <div className="card">
                                        <div className='card-details'>
                                            <div className='card-title'>
                                                <span className="title">Dental Coverage</span>
                                            </div>
                                            <div className='status-time'>
                                                <span className="status active">ACTIVE</span>
                                                <span className="sub-text">09 Jan 2025</span>
                                            </div>
                                        </div>
                                        <div className='image-div'>
                                            <img src={toothIcon} alt='' className='card-image' />
                                        </div>
                                    </div>
                                </Col>


                                {/* Benefits Remaining Card */}
                                <Col>
                                    <div className="card">
                                        <div className='card-details'>
                                            <div className='card-title'>
                                                <span className="title">Benefits Remaining</span>
                                            </div>
                                            <div className='status-time'>
                                                <span className="amount">$1,500.00</span>
                                                <span className="sub-text">Of $1,500.00</span>
                                            </div>
                                        </div>
                                        <div className='image-div'>
                                            <img src={benefitIcon} alt='' className='card-image' />
                                        </div>
                                    </div>
                                </Col>


                                {/* Deductible Remaining Card */}
                                <Col>
                                    <div className="card">
                                        <div className='card-details'>
                                            <div className='card-title'>
                                                <span className="title">Deductible Remaining</span>
                                            </div>
                                            <div className='status-time'>
                                                <span className="amount">$50.00</span>
                                                <span className="sub-text">Of $50.00</span>
                                            </div>
                                        </div>
                                        <div className='image-div'>
                                            <img src={deductibelIcon} alt='' className='card-image' />
                                        </div>
                                    </div>
                                </Col>


                                {/* Verified Date Card */}
                                <Col>
                                    <div className="card">
                                        <div className='card-details'>
                                            <div className='card-title'>
                                                <span className="title">Verified Date</span>
                                            </div>
                                            <div className='status-time'>
                                                <span className="amount">09 Sep '24</span>
                                            </div>
                                        </div>
                                        <div className='image-div'>
                                            <img src={verifiedIcon} alt='' className='card-image' />
                                        </div>
                                    </div>
                                </Col>

                                {/* Appointment ID Card */}
                                <Col>
                                    <div className="card">
                                        <div className='card-details'>
                                            <div className='card-title'>
                                                <span className="title">Appointment ID</span>
                                            </div>
                                            <div className='status-time'>
                                                <span className="amount">{eligibilityDetails.activeCoverage[0].appointmentID}</span>
                                            </div>
                                        </div>
                                        <div className='image-div'>
                                            <img src={appointmentIcon} alt='' className='card-image' />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>


                        <div className="eligibility-check-body-div">
                            <Row>
                                <span className='details-text'>Plan Details</span>
                            </Row>
                            {eligibilityDetails.coInsurance.length == 0 ? (
                                <div>
                                    <span className='no-plan'>No Plan Exist</span>
                                </div>
                            ) :
                                (eligibilityDetails.coInsurance.map((item, index) => (
                                    <div>
                                        <Row className='details-container' key={index}>
                                            <Col>
                                                <div className='details-div'>
                                                    <div className='field-container'>
                                                        <span className='field-header'>Type</span>
                                                        <span className='field-data'>{item.insuranceType}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='details-div'>
                                                    <div className='field-container'>
                                                        <span className='field-header'>Code</span>
                                                        <span className='field-data'>{item.code}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='details-div'>
                                                    <div className='field-container'>
                                                        <span className='field-header'>Group Number</span>
                                                        <span className='field-data'>{eligibilityDetails.groupNumber}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='details-div'>
                                                    <div className='field-container'>
                                                        <span className='field-header'>Network Status</span>
                                                        <span className='field-data'>{item.network}</span>
                                                        <span className='note'>NOTE: The Network Status Is Office Provided</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                                ))}
                        </div>

                        <div className="eligibility-check-body-div">
                            <Row>
                                <span className='details-text'>Patient Details</span>
                            </Row>
                            <Row className='details-container'>
                                <Col>
                                    <div className='details-div'>
                                        <div className='field-container'>
                                            <span className='field-header'>FirstName</span>
                                            <span className='field-data'>{eligibilityDetails.patients[0].firstName}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='details-div'>
                                        <div className='field-container'>
                                            <span className='field-header'>LastName</span>
                                            <span className='field-data'>{eligibilityDetails.patients[0].lastName}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='details-div'>
                                        <div className='field-container'>
                                            <span className='field-header'>Date of birth</span>
                                            <span className='field-data'>{convertDate(eligibilityDetails.patients[0].dateOfBirth)}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='details-div'>
                                        <div className='field-container'>
                                            <span className='field-header'>Gender</span>
                                            <span className='field-data'>-</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='details-div'>
                                        <div className='field-container'>
                                            <span className='field-header'>MemberId</span>
                                            <span className='field-data'>{eligibilityDetails.patients[0].memberId}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='details-div'>
                                        <div className='field-container'>
                                            <span className='field-header'>Coverage Effective Date</span>
                                            <span className='field-data'>10 Dec 2024</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='details-div'>
                                        <div className='field-container'>
                                            <span className='field-header'>Coverage End Date</span>
                                            <span className='field-data'>09 Dec 2025</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        {eligibilityDetails.limitationAndMaximum && (
                            <div className="eligibility-check-body-div">
                                <Row>
                                    <span className='details-text'>Limitations And Maximums</span>
                                </Row>
                                <Table
                                    className='deductible-table'
                                    columns={limitationAndMaximumColumns}
                                    dataSource={transformedLimitationsData}
                                    rowKey={(record) => record.serviceType}
                                    pagination={false}
                                />
                            </div>
                        )}
                        {eligibilityDetails.deductible && (
                            <div className="eligibility-check-body-div">
                                <Row>
                                    <span className='details-text'>Deductible</span>
                                </Row>
                                <Table
                                    className='deductible-table'
                                    columns={deductibleColumns}
                                    dataSource={transformedDeductibleData}
                                    rowKey={(record) => record.serviceType}
                                    pagination={false}
                                />
                            </div>
                        )}
                        {eligibilityDetails.coInsurance && (
                            <div className="eligibility-check-body-div">
                                <Row>
                                    <span className='details-text'>Co-Pay Insurance</span>
                                </Row>
                                <Table
                                    columns={coInsuranceColumns}
                                    dataSource={eligibilityDetails.coInsurance}
                                    rowKey={(record) => record.serviceType}
                                    pagination={false}
                                />
                            </div>
                        )}
                        {treatmentHistory && (
                            <div className="eligibility-check-body-div">
                                <Row>
                                    <span className='details-text'>Treatment History</span>
                                </Row>
                                <Table
                                    columns={treatmentHistoryColumns}
                                    dataSource={transformedTreatmentHistoryData}
                                    rowKey={(record) => record.serviceType}
                                    pagination={false}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default EligibilityCheckDetails;