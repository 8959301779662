import { Button, Col, Dropdown, Menu, message, Modal, Row, Spin } from "antd";
import axios from "axios";
import { forwardRef, useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import addIcon from "../../../../../assets/add.png";
import calendar from "../../../../../assets/calendar.png";
import arrowLeft from "../../../../../assets/chevron_left.png";
import arrowRight from "../../../../../assets/chevron_right_blue.png";
import closeIcon from "../../../../../assets/close.png";
import infoIcon from "../../../../../assets/info.png";
import arrowDown from "../../../../../assets/keyboard_arrow_down.png";
import moreIcon from "../../../../../assets/more_vert.png";
import API_BASE_URL from "../../../../../env";
import AppointmentViewTooltip from "../appointments/AppointmentViewTooltip";
import AppointmentModal from "./appointmentModal/AppointmentModal";

const Appointments = (activeKey) => {
    const Token = localStorage.getItem("Token");
    const { staffId } = useParams();
    const [selectedDate, setSelectedDate] = useState(null);
    const weekdays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const [addShow, setAddShow] = useState(false);
    const [editShow, setEditShow] = useState(false);
    const [datesToShow, setDatesToShow] = useState(18); // Default for 1920px
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [staffDetails, setStaffDetails] = useState({});
    const [selectedAppointment, setSelectedAppointment] = useState();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [appointmentId, setAppointmentId] = useState();
    const [patients, setPatients] = useState([]);

    const handleAddOpen = () => {
        setAddShow(true);
    };

    const handleEditOpen = (id) => {
        const appointment = appointments.find(appointment => appointment.id === id);
        setSelectedAppointment(appointment);
        setEditShow(true);
    };

    useEffect(() => {
        setLoading(true);
        getStaffData();
        getAppointments();
        getPatients();
    }, [staffId]);

    const config = {
        headers: {
            Authorization: `Bearer ${Token}`
        },
        params: {
            providerId: staffId
        }
    }

    const getAppointments = () => {
        axios
            .get(`${API_BASE_URL}/api/v1/Appointments/get-appointments`, config)
            .then(response => {
                setAppointments(response.data.data);
                setLoading(false);
            })
            .catch(err => {
                err.response && message.error(err.response?.data.error ? err.response?.data.error : "Failed to fetch the data");
                setLoading(false);
            })
    }

    const getStaffData = () => {
        axios
            .get(`${API_BASE_URL}/api/v1/providers/get-providers`, config)
            .then(res => {
                let id = res?.data[0].provider_id;
                let fullName = res?.data[0].lastname ? `${res?.data[0].firstname} ${res?.data[0].lastname}` : res?.data[0].firstname;
                let address = `${res?.data[0].city} ${res?.data[0].state} `;
                let zipcode = `${res?.data[0].zipcode}`

                setStaffDetails({
                    id: id,
                    name: fullName,
                    address: address,
                    zipcode: zipcode,
                    profilePictureUrl: res?.data[0].profile_picture_url,
                    birthdate: res?.data[0].birthdate,
                    provider_id: res?.data[0].provider_id,
                    practice_id: res?.data[0].practice_id,
                });
            })
            .catch(err => {

            })
    }

    const getPatients = () => {
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`
            }
        }

        axios
            .get(`${API_BASE_URL}/api/v1/patients/get_all_patients`, config)
            .then(response => {
                setPatients(response.data);
            })
            .catch(err => {
                message.error(err.response?.data.error ? err.response?.data.error : "Failed to fetch the patients data");
            })
    }

    const filteredData = useMemo(() => {
        if (selectedDate !== null && appointments) {
            const inputDateISO = new Date(selectedDate).toLocaleDateString().split('T')[0];

            return appointments?.filter((appointment) => {
                const appointmentDateISO = new Date(appointment.date).toLocaleDateString().split('T')[0];
                return appointmentDateISO === inputDateISO;
            });
        } else {
            return appointments;
        }
    }, [selectedDate, appointments]);

    const ExampleCustomInput = forwardRef(
        ({ value, onClick, className }, ref) => (
            <button onClick={onClick} ref={ref}>
                {value}
            </button>
        ),
    );

    // Generate date range dynamically
    const generateDates = (startDate, totalDays) => {
        const dates = [];
        const start = new Date(startDate);
        for (let i = 0; i < totalDays; i++) {
            const newDate = new Date(start);
            newDate.setDate(start.getDate() + i);
            dates.push(newDate);
        }
        return dates;
    };

    const [visibleDates, setVisibleDates] = useState(
        generateDates(new Date(), datesToShow)
    );

    const scrollDates = (direction) => {
        const newStartDate = new Date(visibleDates[0]);
        newStartDate.setDate(
            direction === "left"
                ? newStartDate.getDate() - 7
                : newStartDate.getDate() + 7
        );
        setVisibleDates(generateDates(newStartDate, datesToShow));
    };

    const handleDateClick = (date) => {
        if (selectedDate === date) {
            setSelectedDate(null);
        } else {
            setSelectedDate(date);
        }
    };

    // Update number of visible dates based on screen size
    const updateDatesToShow = () => {
        const width = window.innerWidth;
        if (width >= 1920) {
            setDatesToShow(18);
        } else if (width >= 1560) {
            setDatesToShow(16);
        } else if (width >= 1280) {
            setDatesToShow(14);
        } else if (width >= 768) {
            setDatesToShow(10);
        } else {
            setDatesToShow(7);
        }
    };

    const onMonthChange = (date) => {
        if (date) {
            const newDate = new Date(selectedDate);
            newDate.setMonth(date.getMonth());
            newDate.setFullYear(date.getFullYear());

            // Ensure the new date is valid (e.g., for months with fewer days like February)
            const lastDayOfNewMonth = new Date(
                newDate.getFullYear(),
                newDate.getMonth() + 1,
                0
            ).getDate();

            if (newDate.getDate() > lastDayOfNewMonth) {
                newDate.setDate(lastDayOfNewMonth);
            }

            setSelectedDate(newDate);
            setVisibleDates(generateDates(newDate, datesToShow));
        }
    }

    useEffect(() => {
        updateDatesToShow();
        window.addEventListener("resize", updateDatesToShow);
        return () => {
            window.removeEventListener("resize", updateDatesToShow);
        };
    }, []);

    useEffect(() => {
        setVisibleDates(generateDates(new Date(), datesToShow));
    }, [datesToShow]);

    const handleAppointmentDelete = (id) => {
        setOpenDeleteModal(true);
        setAppointmentId(id);
    }

    const deleteAppointment = () => {
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`
            },
            params: {
                appointmentSrNo: appointmentId
            }
        }

        axios
            .delete(`${API_BASE_URL}/api/v1/Appointments/delete_appointments_by_appointmentId`, config)
            .then((res) => {
                setOpenDeleteModal(false);
                message.success(res.data);
                getAppointments();
            })
            .catch((err) => {
                setOpenDeleteModal(false);
                message.error(err.res.data);
            })
    }

    const appointmentTime = (time) => {
        if (time) {
            // Check if the time is already in 12-hour format (contains 'AM' or 'PM')
            if (/AM|PM/i.test(time)) {
                return time; // Return the time unchanged
            }

            // If the time is in 24-hour format, convert it
            const [hours, minutes] = time?.split(':').map(Number);

            const period = hours >= 12 ? 'PM' : 'AM';
            const hours12 = hours % 12 || 12; // Convert 0 or 12 to 12

            return `${hours12}:${minutes ? minutes.toString().padStart(2, '0') : '00'} ${period}`;
        }
    };

    const getPatientAge = (id) => {
        const patient = patients.find((patient) => patient.patientId === id);

        return patient?.birthdate ? new Date().getFullYear() - new Date(patient?.birthdate).getFullYear() : '-';
    }

    return (
        <div className="appointments-container">
            <div className="appointments-header">
                <span className="appointment-text">Appointments</span>
                <div className="add-appointment" onClick={() => { handleAddOpen() }}>
                    <img src={addIcon} alt="Add" className="add-icon" />
                    <span className="add-text">Appointment</span>
                </div>
                <div className="calendar-container">
                    <DatePicker
                        showIcon
                        icon={<img src={calendar} alt="Calendar" className="calendar-icon" />}
                        customInput={<ExampleCustomInput />}
                        selected={selectedDate ? selectedDate : new Date()}
                        onChange={(date) => onMonthChange(date)}
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        className="form-control d-inline-block"
                    />
                    <img src={arrowDown} alt="Down Arrow" className="down-arrow-icon" />
                </div>
            </div>
            <div className="dates-slider">
                <img src={arrowLeft} alt="Left Arrow" className="left-arrow" onClick={() => scrollDates("left")} />
                <div className="dates-container">
                    {visibleDates.map((date) => (
                        <div
                            key={date}
                            onClick={() => handleDateClick(date)}
                            className="date-div"
                        >
                            <span className="day-text">
                                {weekdays[date?.getDay()]}
                            </span>
                            <span
                                className={`date ${date?.toDateString() === selectedDate?.toDateString()
                                    ? "active"
                                    : ""}`}
                            >
                                {date?.getDate()}
                            </span>
                        </div>
                    ))}
                </div>
                <img src={arrowRight} alt="Right Arrow" className="right-arrow" onClick={() => scrollDates("right")} />
            </div>
            <div className="appointment-list-container">
                {loading && (
                    <Spin className="spinner" />
                )}
                {!loading && patients && filteredData && filteredData.map((appointment) => (
                    <Row key={appointment.patient_id} className="appointment-row">
                        <Col className="appointment-time-col">
                            <span>{appointmentTime(appointment.time)}</span>
                        </Col>
                        <Col className="appointment-details-col">
                            <span className="appointment-desc">{appointment.treatments[0].code_name}</span>
                            <div className="appointment-info">
                                <span>{appointment.patient_name}</span>
                                <span>{getPatientAge(appointment.patient_id)} Years</span>
                            </div>
                        </Col>
                        <Col className="appointment-actions-col">
                            <AppointmentViewTooltip appointment={appointment} activeKey={activeKey} staffDetails={staffDetails} />
                            <Dropdown
                                overlay={
                                    <Menu>
                                        <Menu.Item
                                            key="edit"
                                            onClick={() => {
                                                handleEditOpen(appointment.id);
                                            }}
                                        >
                                            Edit
                                        </Menu.Item>
                                        <Menu.Item
                                            key="delete"
                                            onClick={() => { handleAppointmentDelete(appointment.id) }}>
                                            Delete
                                        </Menu.Item>
                                    </Menu>
                                }
                                trigger={["click"]}
                            >
                                <img src={moreIcon} alt="More" className="more-icon" />
                            </Dropdown>
                        </Col>
                    </Row>
                ))}

                {!loading && filteredData?.length === 0 && (
                    <div className="no-appointments-container">
                        <span>No Appointments</span>
                    </div>
                )}
            </div>

            <AppointmentModal
                show={addShow}
                setShow={setAddShow}
                mode={"add"}
                module={"staff"}
                inputData={staffDetails}
                getAppointments={getAppointments}
            />
            <AppointmentModal
                show={editShow}
                setShow={setEditShow}
                mode={"edit"}
                module={"staff"}
                inputData={staffDetails}
                selectedAppointment={selectedAppointment}
                getAppointments={getAppointments}
            />

            <Modal
                open={openDeleteModal}
                centered
                closable={false}
                footer={false}
            >
                <div className="modal-body">
                    <div className="close" onClick={() => setOpenDeleteModal(false)}><img src={closeIcon} alt="Close" /></div>
                    <div className="header">
                        <img src={infoIcon} alt="Info" className="info-icon" />
                        Delete Appointment
                    </div>
                    <div className="body">
                        <span className="description">You are trying to delete a appointment,
                            this will remove all the appointment data.
                            Are you sure you want to continue?
                        </span>

                    </div>
                    <div className="buttons-container">
                        <Button className="cancel" onClick={() => setOpenDeleteModal(false)}>No</Button>
                        <Button className="yes" onClick={deleteAppointment}>Yes</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Appointments;