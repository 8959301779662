import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import backIcon from "../../../../assets/arrow_back.png";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../../../../env";

const MedicalHistoryData = () => {
  const navigate = useNavigate();
  const Token = localStorage.getItem("Token");
  const [patientDetails, setPatientDetails] = useState([]);

  const { patientId, recordId } = useParams();

  const getInsuranceDetailsByPatientId = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    axios
      .get(
        // `${API_BASE_URL}/api/v1/insurance/getAllInsuranceDetailsByMemberId?patientId=${patientId}&memberId=${recordId}`,
        `${API_BASE_URL}/api/v1/medicalHistory/getMedicalHistoryByPatientId?recordId=${recordId}&patientId=${patientId}`,
        config
      )
      .then((res) => {
        setPatientDetails(res.data.data[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getInsuranceDetailsByPatientId();
  }, [recordId]);

  return (
    <div className="insurance-data-main-div w-100">
      <Row className="w-100" gutter={[]}>
        <Col span={1}>
          <img
            src={backIcon}
            alt="Back"
            onClick={() => navigate(-1)}
            style={{
              width: 24,
              height: 24,
              cursor: "pointer",
            }}
          />
        </Col>
        <Col span={23}>
          <Row className="w-100" gutter={[20, 20]}>
            <Col span={24}>
              <div className="mem-id-div">{patientDetails?.recordId}</div>
            </Col>
            <Col>
              <div className="data-column">
                <span className="data-header">Patient Id</span>
                <span className="data">{patientDetails?.patientId}</span>
              </div>
            </Col>
            <Col>
              <div className="data-column">
                <span className="data-header">Name</span>
                <span className="data">
                  {patientDetails?.name}
                  {/* {patientDetails?.first_name} {patientDetails?.last_name} */}
                </span>
              </div>
            </Col>
            <Col>
              <div className="data-column">
                <span className="data-header">practice id</span>
                <span className="data">{patientDetails?.practiceId}</span>
              </div>
            </Col>
            <Col>
              <div className="data-column">
                <span className="data-header">Customer ID</span>
                <span className="data">{patientDetails?.custId}</span>
              </div>
            </Col>
            <Col>
              <div className="data-column">
                <span className="data-header">created Date</span>
                <span className="data">{patientDetails?.createdDate}</span>
              </div>
            </Col>
            <Col>
              <div className="data-column">
                <span className="data-header">updated date</span>
                <span className="data">{patientDetails?.updatedDate}</span>
              </div>
            </Col>
            {/* </Row>
          <Row className="w-100" gutter={[]}> */}
            <Col>
              <div className="data-column">
                <span className="data-header">value</span>
                <span className="data">{patientDetails?.value}</span>
              </div>
            </Col>
            <Col>
              <div className="data-column">
                <span className="data-header">description</span>
                <span className="data">
                  {patientDetails?.description}
                  {/* {patientDetails?.first_name} {patientDetails?.last_name} */}
                </span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default MedicalHistoryData;
