import { Button, Col, Row, Steps } from "antd";
import React, { useMemo, useRef, useState } from "react";
import backIcon from "../../../../assets/arrow_back.png";
import { Link, useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import CellWithOverflowTooltip from "../../../CellWithOverflowTooltip/CellWithOverflowTooltip";

const PatientClaimData = ({ patientData }) => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(1);
  const [openEligibilityModal, setOpenEligibilityModal] = useState(false);

  const { Step } = Steps;

  const steps = [
    { label: "Eligibility Check", status: "In-Progress" },
    { label: "Pre Authorization", status: "Pending" },
    { label: "Treatment Rendered", status: "Pending" },
    { label: "Claim Submission", status: "Pending" },
    { label: "Claim Processing", status: "Pending" },
    { label: "Adjudication & EOB", status: "Pending" },
    { label: "Patient Payments", status: "Pending" },
    { label: "Appeal", status: "Pending" },
  ];

  const getCurrentStep = () => {
    return steps.findIndex((step) => step.status === "In-Progress");
  };

  const currentStep = getCurrentStep();

  const onChange = (value) => {
    setCurrent(value);
  };

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridRef = useRef();

  const gridOptions = {
    suppressHorizontalScroll: true, // Enable horizontal scrolling
    overlayNoRowsTemplate:
      '<span class="custom-no-rows">No Claim details to display</span>',
  };

  const rowSelection = useMemo(() => {
    return {
      mode: "multiRow",
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 2,
      resizable: true, // Enable column resizing
      autoHeight: true,
      wrapText: true,
    };
  }, []);

  const columns = [
    {
      headerName: "verification id",
      field: "verificationid",
      cellRenderer: (params) => {
        return (
          <span className="record-id">
            <Link
              onClick={() => setOpenEligibilityModal(true)}
              // to={`${routes_patient_details}/${patientId}/claim-details/${params.value}`}
            >
              {params.value}
            </Link>
          </span>
        );
      },
    },
    { headerName: "verified on", field: "verifiedon" },
    {
      headerName: "PURPOSE",
      field: "PURPOSE",
      cellRenderer: (params) => {
        const { PURPOSE } = params.data;
        return (
          <span>
            <CellWithOverflowTooltip value={PURPOSE} />
          </span>
        );
      },
    },
    { headerName: "claim id", field: "claimid" },
    { headerName: "appointment Id", field: "appointmentId" },
  ];

  const data = [
    {
      verificationid: "113416",
      verifiedon: "11 Sep 2024 01:30 PM",
      PURPOSE: "Root Canal Treatment",
      claimid: "4343",
      appointmentId: "4343",
    },
    {
      verificationid: "113416",
      verifiedon: "11 Sep 2024 01:30 PM",
      PURPOSE: "Root Canal Treatment",
      claimid: "4343",
      appointmentId: "4343",
    },
    {
      verificationid: "113416",
      verifiedon: "11 Sep 2024 01:30 PM",
      PURPOSE: "Root Canal Treatment",
      claimid: "4343",
      appointmentId: "4343",
    },
    {
      verificationid: "113416",
      verifiedon: "11 Sep 2024 01:30 PM",
      PURPOSE: "Root Canal Treatment",
      claimid: "4343",
      appointmentId: "4343",
    },
    {
      verificationid: "113416",
      verifiedon: "11 Sep 2024 01:30 PM",
      PURPOSE: "Root Canal Treatment",
      claimid: "4343",
      appointmentId: "4343",
    },
    {
      verificationid: "113416",
      verifiedon: "11 Sep 2024 01:30 PM",
      PURPOSE: "Root Canal Treatment",
      claimid: "4343",
      appointmentId: "4343",
    },
    {
      verificationid: "113416",
      verifiedon: "11 Sep 2024 01:30 PM",
      PURPOSE: "Root Canal Treatment",
      claimid: "4343",
      appointmentId: "4343",
    },
    {
      verificationid: "113416",
      verifiedon: "11 Sep 2024 01:30 PM",
      PURPOSE: "Root Canal Treatment",
      claimid: "4343",
      appointmentId: "4343",
    },
    {
      verificationid: "113416",
      verifiedon: "11 Sep 2024 01:30 PM",
      PURPOSE: "Root Canal Treatment",
      claimid: "4343",
      appointmentId: "4343",
    },
  ];

  const status = "VERIFIED";

  return (
    <div className="patient-claim-data-main-div">
      <Row
        className="w-100"
        style={{ borderBottom: "1px solid #BABABA", padding: "30px" }}
      >
        <Col span={1}>
          <img
            src={backIcon}
            alt="Back"
            onClick={() => navigate(-1)}
            style={{
              width: 24,
              height: 24,
              cursor: "pointer",
            }}
          />
        </Col>
        <Col span={23}>
          <div className="mem-id-div">113416</div>
          <Row>
            <span className="treatment-name">Root Canal Treatment</span>
          </Row>
          <Row style={{ padding: "20px 0" }} className="w-100">
            {/* <Steps
              progressDot
              current={1}
              labelPlacement="vertical"
              className="w-100"
              items={items}
              /> */}

            <Steps current={currentStep} labelPlacement="vertical">
              {steps.map((step, index) => (
                <Step
                  key={index}
                  title={step.label}
                  onChange={onChange}
                  description={step.status}
                  // status={
                  //   step.status === "In-Progress"
                  //     ? "process"
                  //     : step.status === "Pending"
                  //     ? "wait"
                  //     : "finish"
                  // }
                />
              ))}
            </Steps>
          </Row>
        </Col>
      </Row>
      <div style={{ padding: "20px 30px" }}>
        {current === 1 && (
          <Row className="w-100 eligibility-check-verification-status-row">
            <Col span={1}></Col>
            <Col span={23}>
              <Row>
                <span className="eligibility-check-text">
                  Eligibility Check Verification Status
                </span>
              </Row>
              <Row style={{ margin: "0 0 24px 0" }}>
                <div
                  className={
                    status === "APPROVED"
                      ? "eligibility-check-status-approved"
                      : status === "REJECTED"
                      ? "eligibility-check-status-rejected"
                      : "eligibility-check-status-submitted"
                  }
                >
                  verified
                </div>
              </Row>
              <div className="medical-history-ag">
                <div style={gridStyle} className="ag-theme-quartz">
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columns}
                    rowData={data}
                    // domLayout="autoHeight"
                    // suppressCellSelection={true}
                    rowHeight={80}
                    gridOptions={gridOptions}
                    rowSelection={rowSelection}
                    tooltipShowDelay={0}
                    defaultColDef={defaultColDef}
                  />
                </div>
              </div>
            </Col>
          </Row>
        )}
        {current === 2 && (
          <Row className="w-100 eligibility-check-verification-status-row">
            <Col span={1}></Col>
            <Col span={23}>
              <Row>
                <span className="eligibility-check-text">
                  Appeal for excess claim or denial
                </span>
              </Row>
              <Row style={{ margin: "0 0 24px 0" }}>
                <div
                  className={
                    status === "APPROVED"
                      ? "eligibility-check-status-approved"
                      : status === "REJECTED"
                      ? "eligibility-check-status-rejected"
                      : "eligibility-check-status-submitted"
                  }
                >
                  verified
                </div>
              </Row>
            </Col>
          </Row>
        )}
        <Row className="w-100 d-flex jc-end">
          {current === 2 && (
            <Button
              onClick={() => {
                setCurrent(current - 1);
              }}
            >
              Previous step
            </Button>
          )}
          <Button
            onClick={() => {
              if (current <= 8) {
                setCurrent(current + 1);
              }
            }}
          >
            Proceed
          </Button>
        </Row>
      </div>
    </div>
  );
};

export default PatientClaimData;
