import { AutoComplete, Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Spin, Table, TimePicker } from "antd";
import { Option } from "antd/es/mentions";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import addIcon from "../../../../../../assets/add.png";
import calendarIcon from "../../../../../../assets/calendar.png";
import closeIcon from "../../../../../../assets/close.png";
import deleteIcon from "../../../../../../assets/delete.png";
import default_propic from "../../../../../../assets/default_propic.png";
import moreIcon from "../../../../../../assets/expand_more.png";
import API_BASE_URL from "../../../../../../env";
import ProfilePicture from "../../../../../profileImages/ProfilePicture";
import './AppointmentModal.css';
import { DeleteOutlined } from "@ant-design/icons";

const AppointmentModal = ({ show, setShow, mode, module, inputData, selectedAppointment, getAppointments }) => {
    const Token = localStorage.getItem("Token");
    const { patientId } = useParams();
    const [spinner, setSpinner] = useState(false);
    const [appointmentTime, setAppointmentTime] = useState();
    const [selectedPatient, setSelectedPatient] = useState([]);
    const [patients, setPatients] = useState([]);
    const [providers, setProviders] = useState([]);
    const [codeOptions, setCodeOptions] = useState([]); // Options for AutoComplete
    const [apiData, setApiData] = useState([]); // Raw API data
    const [appointmentDate, setAppointmentDate] = useState();
    const [treatmentsList, setTreatmentsList] = useState([]);
    const [errors, setErrors] = useState({ date: false, time: false, treatments: false });
    // const appointmentOptions = [
    //     { value: "Dental Pain/Discomfort", label: "Dental Pain/Discomfort" },
    //     { value: "Dental Cleaning", label: "Dental Cleaning" },
    //     { value: "Dental Fillings", label: "Dental Fillings" },
    //     { value: "Tooth Extraction", label: "Tooth Extraction" },
    //     { value: "Root Canal Treatment", label: "Root Canal Treatment" },
    //     { value: "Orthodontic Consultation", label: "Orthodontic Consultation" },
    // ];

    useEffect(() => {
        if (show) {
            if (mode === 'edit') {
                setAppointmentDate(selectedAppointment.date ? dayjs(selectedAppointment.date) : null);
                setAppointmentTime(selectedAppointment.time ? convertTo12HourFormat(selectedAppointment.time) : null);

                const updatedTreatments = selectedAppointment.treatments.map((item, index) => ({
                    ...item,
                    key: index + 1, // Ensure each row has a unique key
                }));
                setTreatmentsList(updatedTreatments);
            } else {
                setAppointmentDate(null);
                setAppointmentTime(null);
                setTableFieldsValue();
            }
            getPatientsOrStaff();
            fetchTreatmentCodes();
        }
    }, [show]);

    const setTableFieldsValue = () => {
        setTreatmentsList([
            {
                key: 1,
                service_type: "",
                code_name: "",
                code: "",
                doctor_name: "",
                time: "",
            }
        ])
    }

    const getPatientsOrStaff = () => {
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`,
            },
        };

        if (module === 'staff') {
            axios
                .get(`${API_BASE_URL}/api/v1/patients/get_all_patients`, config)
                .then((res) => {
                    setPatients(res?.data);
                })
                .catch((error) => {
                    message.error(error.response.data.status_message);
                })
        } else {
            const updatedData = {
                ...inputData,
                name: inputData.lastname ? `${inputData.firstname} ${inputData.lastname}` : inputData.firstname
            };
            setSelectedPatient(updatedData);

            axios
                .get(`${API_BASE_URL}/api/v1/providers/get-all-providers`, config)
                .then((res) => {
                    setProviders(res?.data.data);
                })
                .catch((error) => {
                    message.error(error.response.data.status_message);
                })
        }
    };

    const handleClose = () => {
        setShow(false);
        setTableFieldsValue();
        setErrors({ date: false, time: false, treatments: false });
    };

    const handleTimeChange = (_, timeString) => {
        setAppointmentTime(timeString);
    };

    const validateAndSubmit = () => {
        let hasError = false;
        let newErrors = { date: false, time: false, treatments: false };

        if (!appointmentDate) {
            newErrors.date = true;
            hasError = true;
        }
        if (!appointmentTime) {
            newErrors.time = true;
            hasError = true;
        }
        if (treatmentsList.length === 0 || treatmentsList.some(t => !t.service_type || !t.code_name || !t.code || !t.doctor_name || !t.time)) {
            newErrors.treatments = true;
            hasError = true;
        }
        
        setErrors(newErrors);

        if (!hasError) {
            handleAppointmentForm();
        }
    };

    const handleAppointmentForm = () => {

        setSpinner(true);
        const formattedDate = appointmentDate
            ? new Date(appointmentDate.format('YYYY-MM-DD')).toISOString()
            : null;
        const fields = {
            provider_id: module === "staff" ? inputData?.id : treatmentsList[0].doctor_name,
            practice_id: '2',
            patient_id: module === "staff" ? treatmentsList[0].doctor_name : inputData.patientId,
            patient_name: module === "staff" ? getPatientName(treatmentsList[0].doctor_name) : `${inputData.firstname} ${inputData.lastname}`,
            appointment_made_date: mode === 'add' ? new Date() : selectedAppointment?.appointment_made_date,
            updated_at: mode === 'edit' ? new Date() : selectedAppointment?.updated_at,
            date: formattedDate,
            time: convertTo24HourFormat(),
            treatments: treatmentsList.map((treatment) => ({
                service_type: treatment.service_type,
                service_sub_type: "", // Provide a value if applicable
                code: treatment.code,
                code_name: treatment.code_name,
                code_description: "", // Provide a value if applicable
                doctor_name: treatment.doctor_name,
                time: treatment.time
            })),
        }

        if (mode === 'add') {
            const config = {
                headers: {
                    Authorization: `Bearer ${Token}`
                }
            }

            axios
                .post(`${API_BASE_URL}/api/v1/Appointments/createAppointments`, fields, config)
                .then((res) => {
                    message.success('Appointment added successfully');
                    setSpinner(false);
                    handleClose();
                    getAppointments();
                    setTableFieldsValue();
                })
                .catch((err) => {
                    setSpinner(false);
                })
        }
        else {
            const config = {
                headers: {
                    Authorization: `Bearer ${Token}`
                },
                params: {
                    appointmentSrNo: selectedAppointment.id
                }
            }

            axios
                .put(`${API_BASE_URL}/api/v1/Appointments/update_appointments_by_appointmentId`, fields, config)
                .then((res) => {
                    message.success('Appointment updated successfully');
                    setSpinner(false);
                    handleClose();
                    getAppointments();
                })
                .catch(err => {
                    setSpinner(false);
                    message.error(err.response.data.errors);
                })
        }
    }

    const getPatientName = (id) => {
        const patient = patients.length > 0 ? patients.find(patient => patient.patientId === id) : null;
        return patient ? `${patient.firstname} ${patient.lastname}` : 'Unknown Patient';
    }

    const convertTo24HourFormat = () => {
        if (appointmentTime) {
            console.log(appointmentTime);
            
            const [hours, minutesPart] = appointmentTime?.split(':');
            const [minutes, period] = minutesPart.split(' ');

            let hours24 = parseInt(hours, 10);

            if (period.toLowerCase() === 'pm' && hours24 !== 12) {
                hours24 += 12;
            } else if (period.toLowerCase() === 'am' && hours24 === 12) {
                hours24 = 0;
            }

            // Format hours and minutes to ensure two digits
            const formattedHours = hours24.toString().padStart(2, '0');
            const formattedMinutes = minutes.padStart(2, '0');

            return `${formattedHours}:${formattedMinutes}`;
        }
        else {
            return selectedAppointment?.time
        }
    };

    const convertTo12HourFormat = (time) => {
        // Helper function to convert 24-hour time to 12-hour format
        const to12HourFormat = (hours, minutes) => {
            const period = hours >= 12 ? 'PM' : 'AM';
            const hours12 = hours % 12 || 12; // Convert 0 or 12 to 12
            return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
        };

        // Helper function to parse time (handles both 24-hour and 12-hour formats)
        const parseTime = (time) => {
            if (/AM|PM/i.test(time)) {
                const match = time.match(/^(\d+):(\d+)\s*(AM|PM)$/i);
                if (!match) throw new Error("Invalid time format");
                let [_, hours, minutes, period] = match; // Destructure match groups
                hours = Number(hours);
                minutes = Number(minutes);
                if (period.toUpperCase() === 'PM' && hours !== 12) hours += 12; // Convert PM to 24-hour format
                if (period.toUpperCase() === 'AM' && hours === 12) hours = 0; // Handle midnight
                return [hours, minutes];
            } else if (/^\d{1,2}:\d{2}$/.test(time)) {
                return time.split(':').map(Number); // Already in 24-hour format
            } else {
                throw new Error("Invalid time format");
            }
        };

        try {
            // Parse the start time
            const [startHours, startMinutes] = parseTime(time);

            // Convert start and end times to 12-hour format
            const formattedTime = to12HourFormat(startHours, startMinutes);

            return formattedTime;
        } catch (error) {
            console.error("Error parsing time:", error.message);
            return "Invalid time";
        }
    }

    const getAge = (dob) => {
        return dob ? new Date().getFullYear() - new Date(dob).getFullYear() : '-';
    };

    const fetchTreatmentCodes = async () => {
        try {
            const res = await axios.get(
                `https://m67vnbdw5v76oionugzhlmkeri0tguba.lambda-url.ap-south-1.on.aws/data`
            );
            const rawData = res.data.Root;
            const processedData = rawData.map((item) => {
                const rowObj = Object.fromEntries(
                    item.Row.map((entry) => Object.entries(entry)[0])
                );
                return rowObj;
            });
            setApiData(processedData);
        } catch (err) {
            message.error("Failed to fetch API data:", err);
        }
    };

    const handleCodeSearch = (value) => {
        const filteredOptions = apiData
            .filter((item) => item.Code?.toLowerCase().includes(value.toLowerCase()))
            .map((item) => ({
                value: item.Code,
                label: `${item.Code} - ${item.Code_name}`,
            }));
        setCodeOptions(filteredOptions);
    };

    const addTreatment = () => {
        const newKey = treatmentsList.length + 1;
        setTreatmentsList([
            ...treatmentsList,
            {
                key: newKey,
                service_type: "",
                code_name: "",
                code: "",
                doctor_name: "",
                time: "",
            },
        ]);
    };

    const deleteTreatment = (key) => {
        setTreatmentsList(treatmentsList.filter((item) => item.key !== key));
    };

    const handleChange = (key, field, value) => {
        setTreatmentsList((treatments) =>
            treatments.map((item) =>
                item.key === key ? { ...item, [field]: value } : item
            ));
    };

    const handleCodeSelect = (key, value) => {
        const selectedTreatment = apiData.find((item) => item.Code?.toLowerCase() === value.toLowerCase());

        if (selectedTreatment) {
            setTreatmentsList((prev) =>
                prev.map((item) =>
                    item.key === key
                        ? {
                            ...item,
                            service_type: selectedTreatment.cdt_service_type,
                            code_name: selectedTreatment.Code_name,
                            code: selectedTreatment.Code,
                        }
                        : item
                )
            );
        }
    };

    const columns = [
        {
            title: "TREATMENT TYPE",
            dataIndex: "service_type",
            render: (text, record) => (
                <Input
                    disabled
                    value={text}
                />
            ),
        },
        {
            title: "TREATMENT NAME",
            dataIndex: "code_name",
            render: (text, record) => (
                <Input
                    disabled
                    value={text}
                />
            ),
        },
        {
            title: "TREATMENT CODE",
            dataIndex: "code",
            render: (text, record) =>
                <AutoComplete
                    bordered={false}
                    options={codeOptions}
                    onSearch={(value) => handleCodeSearch(value)}
                    onSelect={(value) => handleCodeSelect(record.key, value)}
                    // value={mode === 'edit' ? text : null}
                    placeholder="Search Treatment Code"
                />
        },

        {
            title: `${module === 'staff' ? 'Patient' : 'Doctor'}`,
            dataIndex: "doctor_name",
            render: (text, record) => (
                <Select
                    className="form-input"
                    bordered={false}
                    suffixIcon={<img src={moreIcon} alt="More Icon" />}
                    onChange={(value) => handleChange(record.key, "doctor_name", value)}
                    value={text}
                >
                    {module === 'staff' ?
                        (
                            patients.length > 0 && patients?.map((patient) => (
                                <Option key={patient.patientId} value={patient.patientId}>
                                    {patient.lastname ? `${patient.firstname} ${patient.lastname}` : patient.firstname}
                                </Option>
                            ))
                        )
                        : (
                            providers.length > 0 &&
                            providers?.map((item) => (
                                <Option key={item.provider_id} value={item.provider_id}>
                                    {item.lastname ? `${item.firstname} ${item.lastname}` : item.firstname}
                                </Option>
                            ))
                        )
                    }
                </Select>
            )
        },
        // {
        //     title: "DOCTOR",
        //     dataIndex: "doctor_name",
        //     render: (text, record) => (
        //         <Select
        //             className="form-input"
        //             bordered={false}
        //             suffixIcon={<img src={moreIcon} alt="More Icon" />}
        //             onChange={(value) => handleChange(record.key, "doctor_name", value)}
        //             value={text}
        //         >
        //             {providers.length > 0 &&
        //                 providers?.map((item) => (
        //                     <Option key={item.provider_id} value={item.provider_id}>
        //                         {`${item.firstname} ${item.lastname}`}
        //                     </Option>
        //                 ))}
        //         </Select>
        //     ),
        // },
        {
            title: "DURATION (MIN)",
            dataIndex: "time",
            render: (text, record) => (
                <Input
                    type="number"
                    value={text}
                    onChange={(e) => handleChange(record.key, "time", e.target.value)}
                    style={{ width: 80 }}
                />
            ),
        },
        {
            title: "",
            dataIndex: "action",
            render: (_, record) => (
                <img src={deleteIcon} className="delete-icon" onClick={() => deleteTreatment(record.key)} />
            ),
        },
    ];

    return (
        <Modal
            open={show}
            centered
            closable={false}
            footer={false}
            className="modal-content"
        >
            <Spin spinning={spinner} tip={mode === 'add' ? 'Adding...' : 'Updating...'}>
                {show && inputData &&
                    <div className="modal-body">
                        <div className="close"><img src={closeIcon} alt="Close" onClick={handleClose}/></div>
                        <div className="modal-header">{mode === 'add' ? 'Add' : 'Edit'} Appointment</div>
                        <div className="data-container">
                            <div className="patient-details">
                                <div className="profile-pic">
                                    {inputData.profilePictureUrl ?
                                        (<ProfilePicture
                                            profile_picture_url={
                                                inputData.profilePictureUrl &&
                                                inputData.profilePictureUrl.split(".com/")[1]
                                            }
                                        />)
                                        :
                                        (<img
                                            alt=""
                                            src={default_propic}
                                            style={{
                                                borderRadius: "50%",
                                                width: 58,
                                                height: 58,
                                            }}
                                        />
                                        )}
                                </div>
                                <div className="modal-header-field">
                                    <span className="label">{module === 'staff' ? inputData.id : selectedPatient.patientId}</span>
                                    <span className="data">{module === 'staff' ? inputData.name : selectedPatient.name}</span>
                                </div>
                                <div className="modal-header-field">
                                    <span className="label">Age</span>
                                    <span className="data">{module === 'staff' ? getAge(inputData.birthdate) : getAge(selectedPatient.birthdate)}</span>
                                </div>
                                <div className="modal-header-field">
                                    <span className="label">Provider id</span>
                                    <span className="data">{module === 'staff' ? inputData.provider_id : selectedPatient.providerId}</span>
                                </div>
                                <div className="modal-header-field">
                                    <span className="label">Practice id</span>
                                    <span className="data">{module === 'staff' ? inputData.practice_id : selectedPatient.practiceId}</span>
                                </div>
                            </div>
                            <div className="modal-form">
                                <Row className="fields-container">
                                    <Col className="form-col">
                                        <span className="input-header">Date
                                            <span className="asterisk">*</span>
                                        </span>
                                        <DatePicker
                                            onChange={(date) => setAppointmentDate(date)}
                                            className={`form-input ${errors.date ? 'error' : ''}`}
                                            suffixIcon={<img src={calendarIcon} alt="Calendar" className="calendar-icon" />}
                                            value={appointmentDate}
                                        />
                                        {errors.date && <span className="error-text">Date is required</span>}
                                    </Col>
                                    <Col className="form-col">
                                        <span className="input-header">Time
                                            <span className="asterisk">*</span>
                                        </span>
                                        <TimePicker
                                            suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                            use12Hours
                                            format="h:mm A"
                                            onChange={handleTimeChange}
                                            className={`form-input ${errors.time ? 'error' : ''}`}
                                            value={appointmentTime ? dayjs(appointmentTime, "h:mm A") : null}
                                        />
                                        {errors.time && <span className="error-text">Time is required</span>}
                                    </Col>
                                </Row>
                                <Row className="table-container">
                                    <div className="treatment-header">
                                        <span className="header">Treatment Details</span>
                                        <Button className="add-treatment" onClick={addTreatment}>
                                            <img src={addIcon} alt="Add" className="add-icon" />
                                            <span className="add-text">Treatment</span>
                                        </Button>
                                    </div>
                                    <Table dataSource={treatmentsList} columns={columns} pagination={false} />
                                    {errors.treatments && <span className="error-text">* All treatment fields are required</span>}
                                </Row>

                                <div className="buttons-container">
                                    <Button className="cancel-button" onClick={handleClose}>Cancel</Button>
                                    <Button className="add-button" onClick={validateAndSubmit}>
                                        {mode === 'edit' ? "Save" : "Add"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>}
            </Spin>
        </Modal>
        // <Offcanvas show={show} onHide={handleClose} placement="end" className='appointment-off-canvas'>
        //     <Offcanvas.Header closeButton>
        //         <Offcanvas.Title>{mode === 'add' ? 'Add' : 'Edit'} Appointment</Offcanvas.Title>
        //     </Offcanvas.Header>

        //     <Offcanvas.Body>


        //     </Offcanvas.Body>
        // </Offcanvas>
    )
}

export default AppointmentModal;