import React, { useEffect, useState } from "react";
import patients_alt from "../../assets/patients.png";
import event_seat from "../../assets/event_seat.png";
import sync_alt from "../../assets/sync.png";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Button,
  Col,
  DatePicker,
  message,
  Popover,
  Row,
  Segmented,
  Select,
  Spin,
} from "antd";
import "./Appointments.css";
import { CalendarOutlined, DownOutlined } from "@ant-design/icons";
import API_BASE_URL from "../../env";
import axios from "axios";
import timelapse from "../../assets/timelapse.png";
import close_alt from "../../assets/close.png";
import { UserAuth } from "../../context_api/ContextApi";
import dayjs from "dayjs";

const localizer = momentLocalizer(moment);

const CustomToolbar = ({ onView }) => {
  const { calendarValue, setCalendarValue, syncTime, setSyncTime, setSpinner } =
    UserAuth();
  const [value, setValue] = useState("week");

  const handleTimeChange = (selectedView) => {
    setValue(selectedView);
    onView(selectedView);
  };

  const [treatments, setTreatments] = useState([]);
  const [providers, setProviders] = useState([]);
  const [patients, setPatients] = useState([]);

  const { setSelectedDate } = UserAuth();

  const handleDateChange = (date) => {
    if (date) {
      console.log(dayjs(date).endOf("day").format("YYYY-MM-DDTHH:mm:ss"));
      setSelectedDate([
        dayjs(date).startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
        dayjs(date).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
      ]);
    } else {
      setSelectedDate(null);
    }
  };

  const disabledDate = (current) => {
    const startOfMonth = dayjs().startOf("month");
    const endOfMonth = dayjs().endOf("month");
    return current && (current < startOfMonth || current > endOfMonth);
  };

  // Fetch existing treatments
  const fetchTreatments = async () => {
    setSpinner(true);
    await axios
      .get(
        `${API_BASE_URL}/api/v1/treatments/fetch-all-treatments/{clinicId}?clinicId=${localStorage.getItem(
          "clinic_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
        }
      )
      .then((res) => {
        setTreatments(res.data.data);
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        message.error("Failed to fetch treatments:", err);
      });
  };

  const fetchProviders = async () => {
    setSpinner(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };

    axios
      .get(`${API_BASE_URL}/api/v1/providers/get-all-providers`, config)
      .then((res) => {
        setProviders(res?.data.data);
        setSpinner(false);
      })
      .catch((error) => {
        message.error(error.response.data.status_message);
        setSpinner(false);
      });
  };

  const getPatients = () => {
    setSpinner(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };

    axios
      .get(`${API_BASE_URL}/api/v1/patients/get_all_patients`, config)
      .then((response) => {
        setPatients(response.data);
        setSpinner(false);
      })
      .catch((err) => {
        message.error(
          err.response?.data.error
            ? err.response?.data.error
            : "Failed to fetch the patients data"
        );
        setSpinner(false);
      });
  };

  useEffect(() => {
    fetchTreatments();
  }, []);

  useEffect(() => {
    fetchProviders();
  }, []);

  useEffect(() => {
    getPatients();
  }, []);

  return (
    <div className="custom-toolbar-main-div">
      <Row
        className="w-100 d-flex jc-end"
        style={{
          padding: "0px 30px 10px 30px",
        }}
      >
        <span className="sync-time">Last updated: {syncTime}</span>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 30px 20px 30px",
        }}
      >
        <Col
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Row className="d-flex ai-center">
            <Select
              placeholder="ALL DOCTORS"
              bordered={false}
              style={{ width: 150 }}
              value={
                calendarValue.provider ? calendarValue.provider : "ALL DOCTORS"
              }
              onChange={(value) => {
                setCalendarValue((prev) => ({ ...prev, provider: value }));
              }}
              options={providers?.map((provider) => ({
                value: provider.provider_id,
                label: `${provider.firstname} (${provider.lastname})`,
              }))}
              dropdownRender={(menu) => (
                <>
                  <div
                    style={{
                      padding: "8px",
                    }}
                    className="dropdown-title"
                  >
                    ALL DOCTORS
                  </div>
                  {menu}
                </>
              )}
            />
            <Select
              placeholder="ALL TREATMENTS"
              bordered={false}
              style={{ width: 150 }}
              onChange={(value) => {
                setCalendarValue((prev) => ({ ...prev, treatment: value }));
              }}
              value={
                calendarValue.treatment
                  ? calendarValue.treatment
                  : "ALL TREATMENTS"
              }
              options={treatments?.map((treatment) => ({
                value: treatment.code,
                label: `${treatment.code} ${treatment.code_name}`,
              }))}
              dropdownRender={(menu) => (
                <>
                  <div
                    style={{
                      padding: "8px",
                    }}
                    className="dropdown-title"
                  >
                    ALL TREATMENTS
                  </div>
                  {menu}
                </>
              )}
            />
            <Select
              placeholder="ALL PATIENTS"
              bordered={false}
              style={{ width: 150 }}
              onChange={(value) => {
                setCalendarValue((prev) => ({ ...prev, patient: value }));
              }}
              value={
                calendarValue.patient ? calendarValue.patient : "ALL PATIENTS"
              }
              options={patients?.map((patient) => ({
                value: patient.patientId,
                label: `${patient.firstname} ${patient.lastname}`,
              }))}
              dropdownRender={(menu) => (
                <>
                  <div
                    style={{
                      padding: "8px",
                    }}
                    className="dropdown-title"
                  >
                    ALL PATIENTS
                  </div>
                  {menu}
                </>
              )}
            />
            {(calendarValue.provider ||
              calendarValue.treatment ||
              calendarValue.patient) && (
              <Button
                onClick={() => {
                  setCalendarValue({
                    provider: "",
                    treatment: "",
                    patient: "",
                  });
                }}
              >
                Cancel
              </Button>
            )}
          </Row>
        </Col>
        <Col>
          <Row gutter={[24, 0]} className="d-flex ai-center">
            <Col>
              <Button
                onClick={() => {
                  setSpinner(true);
                  const payload = {};
                  axios
                    .post(
                      `${API_BASE_URL}/api/v1/Appointments/sync-and-save`,
                      payload,
                      {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "Token"
                          )}`,
                        },
                      }
                    )
                    .then((res) => {
                      const time = res.data.data[0].updatedAt;
                      setSyncTime(moment(time).format("DD-MM-YYYY hh:mm A"));
                      localStorage.setItem("appointment_sync", moment());
                      message.success("Sync Success!");
                      setSpinner(false);
                    })
                    .catch((err) => {
                      message.error("error occured");
                      setSpinner(false);
                    });
                }}
                style={{ padding: "18px 7px" }}
                type="text"
                icon={
                  <img
                    alt=""
                    src={sync_alt}
                    style={{ width: 24, height: 24 }}
                  />
                }
              >
                <span className="sync-text">Sync</span>
              </Button>
            </Col>
            {/* <Col>
            <Button
              style={{ padding: "18px 7px" }}
              type="text"
              icon={
                <img alt="" src={add_alt} style={{ width: 24, height: 24 }} />
              }
            >
              <span className="sync-text">Appointment</span>
            </Button>
          </Col> */}
            <Col>
              <CalendarOutlined />
              <DatePicker
                className="datepicker"
                bordered={false}
                style={{ width: 126 }}
                suffixIcon={<DownOutlined />}
                onChange={handleDateChange}
                disabledDate={disabledDate}
              />
            </Col>
            <Col>
              <div className="time-change-segmented">
                <Segmented
                  options={[
                    { label: "Today", value: "day" },
                    { label: "This Week", value: "week" },
                    { label: "This Month", value: "month" },
                  ]}
                  value={value}
                  onChange={(val) => handleTimeChange(val)}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const CustomEvent = ({ event }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);

  return (
    <Popover
      placement="right"
      visible={popoverVisible}
      onVisibleChange={(visible) => setPopoverVisible(visible)}
      content={
        <div className="event-popover">
          <Row className="d-flex ai-center jc-end">
            <img
              alt="close"
              style={{ cursor: "pointer", width: 24 }}
              src={close_alt}
              onClick={() => setPopoverVisible(false)}
            />
          </Row>
          <Row
            style={{
              gap: 8,
              padding: "12px 0",
            }}
            className="d-flex flex-col"
          >
            <Row className="d-flex jc-between ai-center">
              <Col>
                <div className="event-id">{event.id}</div>
              </Col>
              <Col>
                <Row gutter={[24, 0]}>
                  <Col>
                    {/* <img alt="edit" style={{ width: 24 }} src={edit_alt} /> */}
                  </Col>
                  <Col>
                    {/* <img alt="delete" style={{ width: 24 }} src={delete_alt} /> */}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <span className="treatment-name">{event.title}</span>
            </Row>
            <Row>
              <span className="treatment-time">
                {moment(event.start).format("MMMM Do YYYY, h:mm A")} -{" "}
                {moment(event.end).format("h:mm A")}
              </span>
            </Row>
          </Row>
          <Row
            style={{
              padding: "12px 0",
              gap: 20,
              borderBottom: "1px solid var(--Line, #BABABA)",
            }}
          >
            <Col>
              <img alt="patients" style={{ width: 24 }} src={patients_alt} />
            </Col>
            <Col style={{ display: "flex", gap: 12, flexDirection: "column" }}>
              <Row className="d-flex flex-col" style={{ gap: 8 }}>
                <span className="patient-name">{event.patient_name}</span>
                <Row>
                  <div className="patient-id">{event.patient_id}</div>
                </Row>
              </Row>
              <Row
                style={{
                  gap: 20,
                }}
              >
                <Col
                  style={{ display: "flex", gap: 8, flexDirection: "column" }}
                >
                  <Row>
                    <span className="event-text-header">Gender</span>
                  </Row>
                  <Row>
                    <span className="event-text">
                      {event.gender ? event.gender : "N/A"}
                    </span>
                  </Row>
                </Col>
                <Col
                  style={{ display: "flex", gap: 8, flexDirection: "column" }}
                >
                  <Row>
                    <span className="event-text-header">provider id</span>
                  </Row>
                  <Row>
                    <span className="event-text">
                      {event.provider_id ? event.provider_id : "N/A"}
                    </span>
                  </Row>
                </Col>
                <Col
                  style={{ display: "flex", gap: 8, flexDirection: "column" }}
                >
                  <Row>
                    <span className="event-text-header">practice id</span>
                  </Row>
                  <Row>
                    <span className="event-text">
                      {event.practice_id ? event.practice_id : "N/A"}
                    </span>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              padding: "12px 0",
              gap: 20,
            }}
          >
            <Col span={1}>
              <img alt="patients" style={{ width: 24 }} src={event_seat} />
            </Col>
            <Col span={21}>
              <div className="treatment-div">
                <Col>
                  <Row>
                    <span className="event-text">
                      {event?.code} - {event.title ? event.title : "N/A"}
                    </span>
                  </Row>
                  <Row>
                    <span className="event-text-description">
                      ({event.description ? event.description : "N/A"})
                    </span>
                  </Row>
                </Col>
                <Col>
                  <div className="d-flex ai-center">
                    <img alt="delete" style={{ width: 24 }} src={timelapse} />
                    <span className="event-text">
                      {event.length ? event.length : "N/A"} min
                    </span>
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      }
      trigger="click"
    >
      <div className="custom-event-container">
        <Row className="w-100">
          <span className="event-title">{event.title}</span>
        </Row>
        <Row className="w-100">
          <span className="event-time">
            {moment(event.start).format("h:mm A")} -{" "}
            {moment(event.end).format("h:mm A")}
          </span>
        </Row>
      </div>
    </Popover>
  );
};

const CustomCalendar = () => {
  const { calendarValue, setSyncTime, spinner, setSpinner } = UserAuth();
  const [currentView, setCurrentView] = useState(Views.WEEK);
  const [appointments, setAppointments] = useState([]);

  const { selectedDate } = UserAuth();

  const fetchAppointments = async (selectedView) => {
    let startDate;
    let endDate;
    if (selectedDate) {
      startDate = selectedDate[0];
      endDate = selectedDate[1];
    } else {
      setSpinner(true);
      startDate = moment().startOf(selectedView).format("YYYY-MM-DDTHH:mm:ss");
      endDate = moment().endOf(selectedView).format("YYYY-MM-DDTHH:mm:ss");
    }
    await axios
      .get(
        `${API_BASE_URL}/api/v1/Appointments/getAllAppointments?startDate=${startDate}&endDate=${endDate}${
          calendarValue.patient && `&patientId=${calendarValue.patient}`
        }${calendarValue.provider && `&providerId=${calendarValue.provider}`}${
          calendarValue.treatment && `&code=${calendarValue.treatment}`
        }`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        }
      )
      .then((res) => {
        const mappedAppointments = res.data.data.map((appointment) => {
          const startDateTime = moment(
            `${appointment.date.split("T")[0]} ${appointment.time}`,
            "YYYY-MM-DD HH:mm"
          ).toDate();

          const endDateTime = moment(startDateTime)
            .add(appointment.length || 60, "minutes") // Default length to 60 if null
            .toDate();
          return {
            id: appointment.id,
            code: appointment?.treatments[0]?.code,
            title: appointment?.treatments[0]?.service_type,
            description: appointment?.treatments[0]?.code_name,
            time: appointment.time,
            start: startDateTime,
            end: endDateTime,
            gender: appointment.gender,
            provider_id: appointment.provider_id,
            practice_id: appointment.practice_id,
            length: appointment.length,
            patient_name: appointment.patient_name,
            patient_id: appointment.patient_id,
          };
        });

        const time = res.data.data[0]?.updatedAt;
        setSyncTime(moment(time).format("DD-MM-YYYY hh:mm A"));
        setAppointments(mappedAppointments);
        setSpinner(false);
      })
      .catch((error) => {
        message.error(
          error.response?.data?.data?.message || "Failed to fetch appointments"
        );
        setAppointments([]);
        setSpinner(false);
      });
  };

  useEffect(() => {
    fetchAppointments(currentView);
  }, [currentView, calendarValue]);

  useEffect(() => {
    fetchAppointments(currentView);
  }, [selectedDate]);

  return (
    <Spin className="spinner" spinning={spinner}>
      <div className="appointments-main-div">
        <Calendar
          localizer={localizer}
          events={appointments}
          defaultView={Views.WEEK}
          views={[Views.DAY, Views.WEEK, Views.MONTH]}
          view={currentView}
          onView={setCurrentView}
          tooltipAccessor={null}
          components={{
            toolbar: CustomToolbar,
            event: CustomEvent,
          }}
        />
      </div>
    </Spin>
  );
};

export default CustomCalendar;
