import {
  CCarousel,
  CCarouselCaption,
  CCarouselItem,
  CImage,
} from "@coreui/react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ivoryLogo from "../assets/ivorylogo.png";
import background from "../assets/login-background.png";
import API_BASE_URL from "../env";
import {
  routes_forgot_password,
  routes_settings,
  routes_signup
} from "../utils/RoutingConsts";
import {
  common_constants_clinic_id,
  common_constants_email,
  common_constants_loginSuccess,
  common_constants_password,
  common_constants_token
} from "../utils/commonConsts";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const [loginForm] = Form.useForm();
  const [rememberMe, setRememberMe] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const savedEmail = localStorage.getItem(common_constants_email);
    const savedPassword = localStorage.getItem(common_constants_password);

    if (savedEmail && savedPassword) {
      setRememberMe(true);

      loginForm.setFieldsValue({
        email: savedEmail,
        password: savedPassword,
      });
    }
  }, [loginForm]);

  const handleLoginButton = (values) => {
    const payload = {
      email: values.email,
      password: values.password,
    };
    setIsButtonDisabled(true);
    axios
      .post(`${API_BASE_URL}/api/v1/clinic/clinic-login`, payload)
      .then((res) => {
        message.success(common_constants_loginSuccess);
        // localStorage.setItem(
        //   common_constants_name,
        //   `${res.data.data.first_name} ${res.data.data.last_name}`
        // );
        localStorage.setItem("clinic_name", res.data.data.clinic_name);
        localStorage.setItem(common_constants_token, res.headers.authorization);
        localStorage.setItem(common_constants_clinic_id, res.data.data.id);
        navigate(routes_settings);
        // navigate(routes_dashboard);
        if (rememberMe) {
          localStorage.setItem(common_constants_email, values.email);
          localStorage.setItem(common_constants_password, values.password);
        } else {
          localStorage.removeItem(common_constants_email);
          localStorage.removeItem(common_constants_password);
        }
        loginForm.resetFields();
      })
      .catch((err) => {
        setIsButtonDisabled(false);
        message.error(err.response.data.data?.errors[0], 3);
        if (rememberMe) {
          localStorage.setItem(common_constants_email, values.email);
          localStorage.setItem(common_constants_password, values.password);
        } else {
          localStorage.removeItem(common_constants_email);
          localStorage.removeItem(common_constants_password);
        }
        setTimeout(() => setIsButtonDisabled(false), 3000);
      });
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  return (
    <Row className="login-page">
      <Col
        className="logo-desc-container"
        xs={0}
        sm={24}
        md={24}
        lg={14}
        xl={14}
        onClick={(e) => e.preventDefault()}
      >
        <CCarousel indicators>
          <CCarouselItem>
            <Row className="logo-container-div">
              <CImage className="ivory-login-bg" src={background} alt="" />
              <CCarouselCaption className="ivory-description">
                <span className="description-header">
                  AI-Powered Dental Assessments
                </span>
                <span className="description-content">
                  Instantly analyze dental X-rays using advanced AI technology
                  to identify potential issues, recommend treatments, and
                  provide comprehensive reports.
                </span>
              </CCarouselCaption>
            </Row>
          </CCarouselItem>
          <CCarouselItem>
            <Row className="logo-container-div">
              <CImage className="ivory-login-bg" src={background} alt="" />
              <CCarouselCaption className="ivory-description">
                <span className="description-header">
                  AI-Powered Dental Assessments
                </span>
                <span className="description-content">
                  Instantly analyze dental X-rays using advanced AI technology
                  to identify potential issues, recommend treatments, and
                  provide comprehensive reports.
                </span>
              </CCarouselCaption>
            </Row>
          </CCarouselItem>
          <CCarouselItem>
            <Row className="logo-container-div">
              <CImage className="ivory-login-bg" src={background} alt="" />
              <CCarouselCaption className="ivory-description">
                <span className="description-header">
                  AI-Powered Dental Assessments
                </span>
                <span className="description-content">
                  Instantly analyze dental X-rays using advanced AI technology
                  to identify potential issues, recommend treatments, and
                  provide comprehensive reports.
                </span>
              </CCarouselCaption>
            </Row>
          </CCarouselItem>
        </CCarousel>
      </Col>
      <Col className="login-form-container" xs={24} sm={24} md={24} lg={10} xl={10}>
        <img className="ivory-logo-img" src={ivoryLogo} alt="" />
        <Row className="login-form">
          <span className="login-text">Login</span>
          <Form form={loginForm} onFinish={handleLoginButton}>
            <Row className="login-form-field">
              <span className="email-text">Email</span>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email!",
                  },
                ]}
              >
                <Input className="login-form-input" label="email" />
              </Form.Item>
              <span className="error-message"></span>
            </Row>
            <Row className="login-form-field">
              <span className="password-text">Password</span>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                ]}
              >
                <Input.Password className="login-form-input" label="password" />
              </Form.Item>
              <span className="error-message"></span>
            </Row>
            <Row>
              <Form.Item
                name="remember"
                valuePropName="checked"
                style={{ width: "100%" }}
              >
                <Row className="login-form-checkbox">
                  <Col className="remember-me-checkbox-col-tag">
                    <Checkbox
                      data-testid="remember-me-check-box"
                      className="remeber-me-checkbox"
                      type="checkbox"
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                    >
                      Remember me
                    </Checkbox>
                  </Col>
                  <Col>
                    <span
                      className="forgot-password-span-tag"
                      onClick={() => {
                        navigate(routes_forgot_password);
                      }}
                    >
                      Forgot Password?
                    </span>
                  </Col>
                </Row>
              </Form.Item>
            </Row>
            <Button
              className="login-button"
              htmlType="submit"
              disabled={isButtonDisabled}
            >
              Login
            </Button>
            <Divider plain>or</Divider>
            <Row className="buttons-container">
              {/* <Button className="google-button">
                <img src={googleLogo} /> Login With Google
              </Button> */}
              <Button
                className="register-clinic-button"
                onClick={() => {
                  navigate(routes_signup);
                }}
              >
                Register Your Clinic
              </Button>
            </Row>
          </Form>
        </Row>
      </Col>
    </Row>
  );
};

export default Login;
