import { Button, Col, DatePicker, Form, Input, message, Row, Select } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from 'react-router-dom';
import backIcon from "../../../../assets/arrow_back.png";
import calendarIcon from "../../../../assets/calendar.png";
import moreIcon from "../../../../assets/expand_more.png";
import API_BASE_URL from "../../../../env";
import { routes_patient_details } from "../../../../utils/RoutingConsts";
import PhoneInput from "react-phone-input-2";

const AddEligibilityCheck = ({ mode }) => {
    const Token = localStorage.getItem('Token');
    const navigate = useNavigate();
    const { patientId, memberId } = useParams();
    const [addEligibilityCheckForm] = Form.useForm();
    const [appointments, setAppointments] = useState();
    const dateFormat = 'DD MMM YYYY';

    const genders = [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Other", label: "Other" }
    ];

    const networkStatus = [
        { value: "In-Network", label: "In-Network" },
        { value: "Out-Of-Network", label: "Out-Of-Network" }
    ];

    const providerType = [
        { value: "General Dentist", label: "General Dentist" },
        { value: "Orthodontist", label: "Orthodontist" },
        { value: "Periodontist", label: "Periodontist" },
        { value: "Endodontist", label: "Endodontist" },
        { value: "Oral Surgeon", label: "Oral Surgeon" },
    ];

    useEffect(() => {
        if (mode === 'edit') {
            eligibilityCheckDetail()
        } else {
            getPatientById();
            getAppointments();
        }
    }, [patientId, memberId]);

    const config = {
        headers: {
            Authorization: `Bearer ${Token}`
        }
    };

    const eligibilityCheckDetail = () => {
        axios
            .get(`${API_BASE_URL}/api/v1/insurance_eligibility_check/getAllEligibilityDetailsByMemberId?patientMemberId=${memberId}`, config)
            .then((res) => {
                setFieldsValue(res.data.data[0]);
            })
            .catch(err => message.error(err.response.data.data.errors))
    };

    const getPatientById = () => {
        axios.get(
            `${API_BASE_URL}/api/v1/patients/fetchPatientsByPatientId?patientId=${patientId}`,
            config
        )
            .then((res) => {
                setPatientFields(res.data);
            })
            .catch((error) => {
                console.error("Failed to fetch patient data:", error)
            })
    }

    const setPatientFields = (patientData) => {
        addEligibilityCheckForm.setFieldsValue({
            firstName: patientData.firstname,
            lastName: patientData.lastname,
            birthdate: dayjs(patientData.birthdate),
        })
    }

    const setFieldsValue = (values) => {
        addEligibilityCheckForm.setFieldsValue({
            firstName: values.patients[0].firstName,
            lastName: values.patients[0].lastName,
            birthdate: dayjs(values.patients[0].dateOfBirth),
            member_id: values.patients[0].memberId,
            relationship: values.patients[0].relationship,
            appointment_sr_no: values.patients[0].appointmentID,
            sub_firstName: values.subscribers[0].firstName,
            sub_lastName: values.subscribers[0].lastName,
            sub_memberId: values.subscribers[0].memberId,
            sub_birthdate: dayjs(values.subscribers[0].dateOfBirth),
            provider_type: values.providers[0].type,
            taxId: values.providers[0].taxId,
            npi: values.providers[0].npi,
            provider_firstName: values.providers[0].firstName,
            provider_lastName: values.providers[0].lastName,
            salutation: values.providers[0].salutation,
            degree: values.providers[0].degree,
            license: values.providers[0].license,
            phone: values.providers[0].address.phone,
            address1: values.providers[0].address.address1,
            city: values.providers[0].address.city,
            state: values.providers[0].address.state,
            zipcode: values.providers[0].address.zipCode,
            payerName: values.payers[0].name,
            payerIdCode: values.payers[0].payerIdCode,
            groupNumber: values.groupNumber,
            procedureCode: values.procedureCode
        })
    }

    const getAppointments = () => {
        axios
            .get(`${API_BASE_URL}/api/v1/Appointments/get-appointments?patientId=${patientId}`, config)
            .then(response => {
                const appointmentIDs = response.data.data.map((item) => ({
                    value: item.appointment_sr_no, label: item.appointment_sr_no
                }));
                setAppointments(appointmentIDs);
                // setLoading(false);
            })
            .catch((err) => {
                message.error(err.response?.data?.error);
            })
    }

    const convertDate = (dob) => {
        const date = new Date(dob);
        return date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
        });
    };

    const handleFormSubmit = (values) => {
        const payload = {
            patient: {
                patientID: patientId,
                firstName: values.firstName,
                lastName: values.lastName,
                dateOfBirth: values.birthdate,
                memberId: values.member_id,
                relationship: values.relationship,
                appointmentID: values.appointment_sr_no
            },
            subscriber: {
                firstName: values.sub_firstName,
                lastName: values.sub_lastName,
                dateOfBirth: values.sub_birthdate,
                memberId: values.sub_memberId
            },
            provider: {
                type: values.provider_type,
                taxId: values.taxId,
                npi: values.npi,
                firstName: values.provider_firstName,
                lastName: values.provider_lastName,
                salutation: values.salutation,
                degree: values.degree,
                license: values.license,
                address: {
                    phone: values.phone,
                    address1: values.address1,
                    address2: "",
                    city: values.city,
                    state: values.state,
                    zipCode: values.zipcode
                }
            },
            payer: {
                name: values.payerName,
                payerIdCode: values.payerIdCode
            },
            groupNumber: values.groupNumber,
            procedureCode: values.procedureCode
        };

        const appointmentID = values.appointment_sr_no;

        if (mode === 'add') {
            axios
                .post(`${API_BASE_URL}/api/v1/insurance_eligibility_check/insurance-eligibility_check`, payload, config)
                .then((res) => {
                    message.success('Eligibility check added successfully');
                    navigate(`${routes_patient_details}/${patientId}`);
                })
                .catch(err => {
                    message.error("Failed to add, please try again");
                });
        } else {
            axios
                .put(`${API_BASE_URL}/api/v1/insurance_eligibility_check/eligibility_check_update?patientId=${patientId}&memberId=${memberId}&appointmentID=${appointmentID}`, payload, config)
                .then((res) => {
                    message.success('Eligibility check updated successfully');
                    navigate(`${routes_patient_details}/${patientId}`);
                })
                .catch(err => message.error(err.response.data.data.errors || "Failed to update, please try again"));
        }
    }

    return (
        <div className="add-eligibility-check-container">
            <Helmet>
                <title>{mode === 'add' ? 'Add' : 'Edit'} Eligibility Check</title>
            </Helmet>

            <div className='add-eligibility-check-view'>
                <div className='add-eligibility-check-header'>
                    <img src={backIcon} alt='Back' className='back-icon' onClick={() => navigate(-1)} />
                    <span>{mode === 'add' ? 'Add' : 'Edit'} Eligibility Check</span>
                </div>
                <div className='add-eligibility-check-body'>
                    <Form
                        form={addEligibilityCheckForm}
                        onFinish={handleFormSubmit}
                        className="eligibility-form"
                    >
                        <Row className='add-eligibility-form-row'>
                            {mode === 'edit' && (
                                <Col>
                                    <span className="input-header">
                                        Verification ID <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item name="verification_id">
                                        <Input
                                            className="form-input"
                                            defaultValue={memberId}
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                            <Col>
                                <span className="input-header">
                                    Appointment ID <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="appointment_sr_no" rules={[{ required: true }]}>
                                    <Select
                                        className="form-input"
                                        bordered={false}
                                        placeholder="Select"
                                        suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                        options={appointments}
                                    />
                                </Form.Item>
                            </Col>
                            {/* <Col>
                                <span className="input-header">
                                    Insurance Provider <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="insurance_provider" rules={[{ required: true }]}>
                                    <Select
                                        className="form-input"
                                        bordered={false}
                                        placeholder="Select"
                                        suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                        options={appointments}
                                    />
                                </Form.Item>
                            </Col> */}
                            <Col>
                                <span className="input-header">
                                    Procedure Code <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="procedureCode" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            {mode === 'edit' && (
                                <Col>
                                    <span className="input-header">
                                        Verified Date <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item name="verified_date">
                                        <DatePicker
                                            className="form-input"
                                            suffixIcon={<img src={calendarIcon} alt="Calendar" className="calendar-icon" />}
                                            defaultValue={dayjs()}
                                            format={dateFormat}
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                        <Row><span className='form-header'>Payer Details</span></Row>
                        <Row className='add-eligibility-form-row'>
                            {/* <Col>
                                <span className="input-header">
                                    Type <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="type" rules={[{ required: true }]}>
                                    <Select
                                        className="form-input"
                                        bordered={false}
                                        placeholder="Select"
                                        suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                        options={appointments}
                                    />
                                </Form.Item>
                            </Col> */}
                            <Col>
                                <span className="input-header">
                                    Name <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="payerName" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    ID code <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="payerIdCode" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Group Number <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="groupNumber" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Network Status
                                </span>
                                <Form.Item name="network_status">
                                    <Select
                                        className="form-input"
                                        bordered={false}
                                        placeholder="Select"
                                        suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                        options={networkStatus}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row><span className='form-header'>Patient Details</span></Row>
                        <Row className='add-eligibility-form-row'>
                            <Col>
                                <span className="input-header">
                                    First Name <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="firstName" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Last Name <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="lastName" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Date Of Birth <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="birthdate" rules={[{ required: true }]}>
                                    <DatePicker
                                        className="form-input"
                                        suffixIcon={<img src={calendarIcon} alt="Calendar" className="calendar-icon" />}
                                        format={dateFormat}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Gender
                                </span>
                                <Form.Item name="gender">
                                    <Select
                                        className="form-input"
                                        bordered={false}
                                        placeholder="Select"
                                        suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                        options={genders}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Member ID <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="member_id" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Relationship <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="relationship" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Coverage Effective Date
                                </span>
                                <Form.Item name="coverage_effective">
                                    <DatePicker
                                        className="form-input"
                                        suffixIcon={<img src={calendarIcon} alt="Calendar" className="calendar-icon" />}
                                        format={dateFormat}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Coverage End Date
                                </span>
                                <Form.Item name="coverage_end">
                                    <DatePicker
                                        className="form-input"
                                        suffixIcon={<img src={calendarIcon} alt="Calendar" className="calendar-icon" />}
                                        format={dateFormat}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row><span className='form-header'>Subscriber Details</span></Row>
                        <Row className='add-eligibility-form-row'>
                            <Col>
                                <span className="input-header">
                                    First Name <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="sub_firstName" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Last Name <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="sub_lastName" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Date Of Birth <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="sub_birthdate" rules={[{ required: true }]}>
                                    <DatePicker
                                        className="form-input"
                                        suffixIcon={<img src={calendarIcon} alt="Calendar" className="calendar-icon" />}
                                        format={dateFormat}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Member ID <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="sub_memberId" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row><span className='form-header'>Provider Details</span></Row>
                        <Row className='add-eligibility-form-row'>
                            <Col>
                                <span className="input-header">
                                    Salutation <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="salutation" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    First Name <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="provider_firstName" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Last Name <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="provider_lastName" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Degree <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="degree" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Tax ID <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="taxId" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    NPI <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="npi" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    License <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="license" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Type <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="provider_type" rules={[{ required: true }]}>
                                    <Select
                                        className="form-input"
                                        bordered={false}
                                        placeholder="Select"
                                        suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                        options={providerType}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Phone <span className="asterisk">*</span>
                                </span>
                                <Form.Item
                                    name="phone"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter phone number",
                                        },
                                        {
                                            pattern: /^[1-9]\d*$/,
                                            message: "Phone number cannot start with a zero",
                                        },
                                        // { validator: validatePhoneNumber },
                                    ]}
                                >
                                    <PhoneInput
                                        country={"us"}
                                        onlyCountries={["us", "in"]}
                                        inputClass="form-control"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Address <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="address1" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    City <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="city" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    State <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="state" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="input-header">
                                    Zip Code <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="zipcode" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className='add-eligibility-form-row'>
                            <Button className="add-button" htmlType="submit">{mode === 'add' ? 'Add' : 'Update'}</Button>
                        </Row>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddEligibilityCheck;