import React, { useState } from "react";
import "./Settings.css";
import { Col, Input, Row, Tabs } from "antd";
import General from "./general_tab/General";
import Role from "./role_tab/Role";
import AccessControl from "./accesscontrol_tab/AccessControl";
import { SearchOutlined } from "@ant-design/icons";
import add_icon from "../../assets/add.png";
import { UserAuth } from "../../context_api/ContextApi";
import {
  _access_control_tab,
  _general_tab,
  _role_tab,
} from "../../utils/commonConsts";

const Settings = () => {
  const [tabExtraContent, settabExtraContent] = useState({
    tab: _general_tab,
    subTab: "1",
  });
  const { showDrawer, setOpenHoliday } = UserAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [roles, setRoles] = useState([]);

  const [activeTab, setActiveTab] = useState(_general_tab); // Default tab is 1
  const [selectedStaffRoleId, setSelectedStaffRoleId] = useState(null);
  const [selectedStaffRoleName, setSelectedStaffRoleName] = useState(null);

  const treatmentCostsRef = React.useRef(null);
  const fetchRolesRef = React.useRef(null);

  // Function to trigger Add Treatment in the child component
  const handleAddTreatment = () => {
    if (treatmentCostsRef.current) {
      treatmentCostsRef.current.addTreatment();
    }
  };

  const operations = (
    <div>
      {tabExtraContent.tab === _general_tab && tabExtraContent.subTab === "2" && (
        <Row style={{ gap: 30 }} className="d-flex ai-center">
          <Col>
            <div
              className="tab-extra-text d-flex ai-center"
              onClick={showDrawer}
            >
              <img src={add_icon} alt="" style={{ width: 24, height: 24 }} />
              Document
            </div>
          </Col>
          <Col>
            <Input
              suffix={<SearchOutlined />}
              placeholder="SEARCH"
              className="search-input"
              disabled
            />
          </Col>
        </Row>
      )}
      {tabExtraContent.tab === _general_tab && tabExtraContent.subTab === "3" && (
        <Row style={{ gap: 30 }} className="d-flex ai-center">
          <Col className="">
            <div
              className="tab-extra-text d-flex ai-center"
              onClick={handleAddTreatment}
            >
              <img src={add_icon} alt="" style={{ width: 24, height: 24 }} />
              Treatment
            </div>
          </Col>
          <Col>
            <Input
              suffix={<SearchOutlined />}
              placeholder="SEARCH"
              className="search-input"
              disabled
            />
          </Col>
        </Row>
      )}
      {tabExtraContent.tab === _general_tab && tabExtraContent.subTab === "5" && (
        <Row style={{ gap: 30 }} className="d-flex ai-center">
          <Col className="">
            <div
              className="tab-extra-text d-flex ai-center"
              onClick={() => setOpenHoliday(true)}
            >
              <img src={add_icon} alt="" style={{ width: 24, height: 24 }} />
              Holiday
            </div>
          </Col>
          <Col>
            <Input
              suffix={<SearchOutlined />}
              placeholder="SEARCH"
              className="search-input"
              disabled
            />
          </Col>
        </Row>
      )}
      {tabExtraContent.tab === _general_tab && tabExtraContent.subTab === "7" && (
        <Row style={{ gap: 30 }} className="d-flex ai-center">
          <Input
            suffix={<SearchOutlined />}
            placeholder="SEARCH"
            className="search-input"
          />
        </Row>
      )}
      {tabExtraContent.tab === _role_tab && (
        <Row style={{ gap: 30 }} className="d-flex ai-center">
          <Col className="">
            <div
              className="tab-extra-text d-flex ai-center"
              onClick={() => setIsDrawerOpen(true)}
            >
              <img src={add_icon} alt="" style={{ width: 24, height: 24 }} />
              Role
            </div>
          </Col>
          <Col>
            <Input
              suffix={<SearchOutlined />}
              placeholder="SEARCH"
              className="search-input"
              disabled
            />
          </Col>
        </Row>
      )}
    </div>
  );

  const onChange = (key) => {
    if (fetchRolesRef.current) {
      fetchRolesRef.current.fetchRoles();
    }
    settabExtraContent({ tab: key, subTab: tabExtraContent.subTab });
    setActiveTab(key);
    if (key !== _access_control_tab) {
      setRoles(null);
      setSelectedStaffRoleId(null);
      setSelectedStaffRoleName(null);
    }
  };

  const items = [
    {
      key: _general_tab,
      label: <span>General</span>,
      children: (
        <General
          // treatmentCostsRef={treatmentCostsRef}
          settabExtraContent={settabExtraContent}
          tabExtraContent={tabExtraContent}
          forwardedRef={treatmentCostsRef}
        />
      ),
    },
    {
      key: _role_tab,
      label: "Role",
      children: (
        <Role
          setSelectedStaffRoleId={setSelectedStaffRoleId}
          setSelectedStaffRoleName={setSelectedStaffRoleName}
          isDrawerOpen={isDrawerOpen}
          setActiveTab={setActiveTab}
          setIsDrawerOpen={setIsDrawerOpen}
          setRoles={setRoles}
          roles={roles}
        />
      ),
    },
    {
      key: _access_control_tab,
      label: "Access Control",
      children: (
        <AccessControl
          ref={fetchRolesRef}
          selectedStaffRoleId={selectedStaffRoleId}
          setSelectedStaffRoleId={setSelectedStaffRoleId}
          selectedStaffRoleName={selectedStaffRoleName}
          setSelectedStaffRoleName={setSelectedStaffRoleName}
          setRoles={setRoles}
          roles={roles}
        />
      ),
    },
  ];

  return (
    <div className="settings-menu-main-div">
      <Tabs
        activeKey={activeTab}
        defaultActiveKey={_general_tab}
        items={items}
        onChange={onChange}
        tabBarExtraContent={operations}
      />
    </div>
  );
};

export default Settings;
