import { Divider, Tooltip } from "antd";
import { useEffect, useState } from "react";
import closeIcon from "../../../../../assets/close.png";

const AppointmentViewTooltip = ({ appointment, activeKey, staffDetails }) => {
    // const Token = localStorage.getItem("Token");
    // const { staffId } = useParams();
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    // useEffect(() => {
    //     getStaffData();
    // }, [staffId]);

    useEffect(() => {
        setIsTooltipOpen(false);
    }, [activeKey, appointment,staffDetails]);

    const handleTitleClick = () => {
        setIsTooltipOpen(true);
    };

    const handleCloseClick = () => {
        setIsTooltipOpen(false);
    };

    const appointmentDate = () => {
        if (appointment.date) {
            const date = new Date(appointment.date);

            const options = { day: '2-digit', month: 'long', year: 'numeric' };
            return date.toLocaleDateString('en-US', options);
        }
    }

    const appointmentTime = () => {
        if (appointment.time) {
            const [hours, minutes] = appointment.time.split(':').map(Number);

            const period = hours >= 12 ? 'PM' : 'AM';
            const hours12 = hours % 12 || 12; // Convert 0 or 12 to 12

            return `${hours12}:${minutes ? minutes.toString().padStart(2, '0') : '00'} ${period}`;
        }
    };

    return (
        <Tooltip
            title={
                staffDetails && (<div className="tooltip-container">
                    <div className="close-button">
                        <img
                            src={closeIcon}
                            className="close-icon"
                            onClick={handleCloseClick}
                            alt="Close"
                        />
                    </div>

                    <div className="data-container">
                        <div className="notes-data-header-row">
                            <span className="data-label">{appointment.appointment_sr_no}</span>
                            <span className="title">{appointment.treatments[0].code_name}</span>
                            <span className="app-date">{appointmentDate()} {appointmentTime()}</span>
                        </div>
                        <Divider className='note-divider'></Divider>

                        <div className="d-flex w-100">
                            <div className="notes-data-row">
                                <span className="data-label">Doctor Details</span>
                                <span className="doctor-name">{staffDetails?.name}</span>
                            </div>
                            <div className="notes-data-row">
                                <span className="data-label">Branch</span>
                                <div className="note-details">
                                    <span>{staffDetails?.address}</span>
                                    <span>{staffDetails?.zipcode}</span>
                                </div>
                            </div>
                        </div>
                        <Divider className='note-divider'></Divider>

                        <div className="notes-data-row">
                            <span className="data-label">Patient Details</span>
                            <span className="date">{appointment.patient_id}</span>
                            <span className="doctor-name">{appointment.patient_name}</span>
                        </div>
                        <Divider className='note-divider'></Divider>

                        <div className="notes-data-row">
                            <span className="data-label">Notes</span>
                            <div className="app-notes">
                                <div>
                                    <span>Medical History: </span> No significant changes in medical
                                    history since the last visit. Patient reports no
                                    allergies and no current medications.
                                </div>

                                <div className="note-desc">
                                    <div><span>Next Appointment: </span>Scheduled for March 15, 2025</div>
                                    <div><span>Dentist: </span>Dr. Sarah Williams</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)
            }
            color="white"
            overlayInnerStyle={{
                color: "#000",
                padding: "10px",
                borderRadius: "8px",
            }}
            placement="left"
            trigger="click" // Tooltip will appear only on click
            open={isTooltipOpen}
        >
            <span
                className="view-details-text"
                onClick={handleTitleClick}
            >
                View Details
            </span>
        </Tooltip >
    )
}

export default AppointmentViewTooltip;