import { Col, message, Row, Spin, Table } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import backIcon from "../../../../assets/arrow_back.png";
import editIcon from "../../../../assets/edit.png";
import API_BASE_URL from '../../../../env';
import { routes_patient_details } from '../../../../utils/RoutingConsts';
import AppointmentModal from '../../../staff/staff-details/tabs/appointments/appointmentModal/AppointmentModal';
import './AppointmentDetails.css';
import CellWithOverflowTooltip from '../../../CellWithOverflowTooltip/CellWithOverflowTooltip';

const AppointmentDetails = () => {
    const Token = localStorage.getItem("Token");
    const navigate = useNavigate();
    const { patientId, appointmentId } = useParams();
    const [appointment, setAppointment] = useState();
    const [staff, setStaff] = useState();
    const [patient, setPatient] = useState();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAppointmentData();
    }, [setAppointment])

    const config = {
        headers: {
            Authorization: `Bearer ${Token}`
        },
        params: {
            appointmentSrNo: appointmentId
        }
    }

    const getAppointmentData = () => {
        setLoading(true);
        axios
            .get(`${API_BASE_URL}/api/v1/Appointments/get_appointments_by_appointmentId`, config)
            .then(res => {
                setAppointment(res.data.data);
                getStaffData(res.data.data.provider_id);
                getPatientById(res.data.data.patient_id);
            })
            .catch(err => {
                message.error(err.response?.data.error ? err.response?.data.error : "Failed to fetch the data");
                setLoading(false);
            })
    }

    const getStaffData = () => {
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`
            },
        }

        axios
            .get(`${API_BASE_URL}/api/v1/providers/get-all-providers`, config)
            .then(res => {
                setStaff(res.data.data);
                setLoading(false);
            })
            .catch(err => {
                message.error(err.response?.data.error ? err.response?.data.error : "Failed to fetch the data.")
            })
    }

    const getPatientById = (patientId) => {
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`
            },
            params: {
                patientId: patientId
            }
        }

        axios
            .get(`${API_BASE_URL}/api/v1/patients/fetchPatientsByPatientId`, config)
            .then(res => {
                const patient = [];
                patient.push(res.data);
                setPatient(patient[0]);
                setLoading(false);
            })
            .catch(err => {
                message.error(err.response?.data.error ? err.response?.data.error : "Failed to fetch the patients data");
            })
    }

    const appointmentDate = () => {
        const date = new Date(appointment?.date);

        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-UK', options);
    }

    const appointmentTime = (time) => {
        // Helper function to convert 24-hour time to 12-hour format
        const to12HourFormat = (hours, minutes) => {
            const period = hours >= 12 ? 'PM' : 'AM';
            const hours12 = hours % 12 || 12; // Convert 0 or 12 to 12
            return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
        };

        // Helper function to parse time (handles both 24-hour and 12-hour formats)
        const parseTime = (time) => {
            if (/AM|PM/i.test(time)) {
                const match = time.match(/^(\d+):(\d+)\s*(AM|PM)$/i);
                if (!match) throw new Error("Invalid time format");
                let [_, hours, minutes, period] = match; // Destructure match groups
                hours = Number(hours);
                minutes = Number(minutes);
                if (period.toUpperCase() === 'PM' && hours !== 12) hours += 12; // Convert PM to 24-hour format
                if (period.toUpperCase() === 'AM' && hours === 12) hours = 0; // Handle midnight
                return [hours, minutes];
            } else if (/^\d{1,2}:\d{2}$/.test(time)) {
                return time.split(':').map(Number); // Already in 24-hour format
            } else {
                throw new Error("Invalid time format");
            }
        };

        try {
            // Parse the start time
            const [startHours, startMinutes] = parseTime(time);

            // Convert start and end times to 12-hour format
            const startFormatted = to12HourFormat(startHours, startMinutes);

            return startFormatted;
        } catch (error) {
            console.error("Error parsing time:", error.message);
            return "Invalid time";
        }
    };

    const handleEditAppointment = () => {
        setShow(true);
    }

    const getStaffName = (id) => {
        if (staff) {
            const provider = staff.find((item) => item.provider_id === id);
            return provider.lastname ? `${provider?.firstname} ${provider?.lastname}` : provider.firstname
        }
    }

    const columns = [
        {
            title: "TREATMENT TYPE",
            dataIndex: "service_type",
            render: (text) => (
                <span>{text}</span>
            ),
        },
        {
            title: "TREATMENT NAME",
            dataIndex: "code_name",
            render: (text) => (
                <CellWithOverflowTooltip value={text} />
            ),

        },
        {
            title: "TREATMENT CODE",
            dataIndex: "code",
            render: (text) => (
                <span>{text}</span>
            ),
        },
        {
            title: "DOCTOR",
            dataIndex: "doctor_name",
            render: (text) => (
                <span>{appointment?.patient_id === text ? getStaffName(appointment?.provider_id) : getStaffName(text)}</span>
            ),
        },
        {
            title: "DURATION (MIN)",
            dataIndex: "time",
            render: (text) => (
                <span>{text}</span>
            ),
        },
    ];

    return (
        <div className="appointment-details-container">
            <Helmet>
                <title>Appointment Details</title>
            </Helmet>

            {loading && (
                <Spin className='spinner'></Spin>
            )}

            {!loading && appointment && (
                <div className='appointment-details-view'>
                    <div className='appointment-details-header'>
                        <img src={backIcon} alt='Back' className='back-icon' onClick={() => navigate(`${routes_patient_details}/${patientId}`)} />
                        <div className='appointment-info'>
                            <div className='appointment-basic-info'>
                                <Row>
                                    <span className='appointment-id'>{appointmentId}</span>
                                </Row>
                                <Row className='appointment-date-time'>
                                    <span>{appointmentDate()}</span>
                                    <span>{appointmentTime(appointment.time)}</span>
                                </Row>
                            </div>
                            <div className='appointment-personal-info'>
                                {/* {staff && (
                                    <Col className='appointment-info-col'>
                                        <span className='label'>Doctor</span>
                                        <Row className='doctor-details'>
                                            <span className='doctor-name'>{`${staff.firstname} ${staff.lastname}`}</span>
                                            <span className='doctor-type'>{staff.specialty_code ? staff.specialty_code : 'N/A'}</span>
                                        </Row>
                                    </Col>
                                )}
                                {staff && (
                                    <Col className='appointment-info-col'>
                                        <span className='label'>Clinic branch</span>
                                        <span className='address'>{`${staff.city} ${staff.state}, ${staff.zipcode}`}</span>
                                    </Col>
                                )} */}
                                <Col className='appointment-info-col'>
                                    <span className='label'>Patient Id</span>
                                    <span className='patient-id'>{appointment.patient_id}</span>
                                </Col>
                                <Col className='appointment-info-col'>
                                    <span className='label'>Patient name</span>
                                    <span className='patient-name'>{appointment.patient_name}</span>
                                </Col>
                                <Col className='appointment-info-col'>
                                    <span className='label'>Provider Id</span>
                                    <span className='patient-name'>{appointment.provider_id}</span>
                                </Col>
                                <Col className='appointment-info-col'>
                                    <span className='label'>Practice Id</span>
                                    <span className='patient-name'>{appointment.practice_id}</span>
                                </Col>
                                {/* <Col className='appointment-info-col'>
                                    <span className='label'>Age</span>
                                    {patient && (
                                        <span className='patient-name'>{patient?.birthdate ? `${new Date().getFullYear() - new Date(patient?.birthdate).getFullYear()} Years` : '-'}</span>
                                    )}
                                </Col> */}
                                <Col className='appointment-info-col'>
                                    <span className='label'>Status</span>
                                    <span className='status'>{appointment.status ? appointment.status : 'Completed'}</span>
                                </Col>
                                <img src={editIcon} className='edit-icon' onClick={handleEditAppointment} />
                            </div>
                        </div>
                    </div>
                    <div className='appointment-details-body'>
                        <div className='treatment-details-container'>
                            <span className='treatment-text'>Treatment</span>
                            <Table dataSource={appointment.treatments} columns={columns} pagination={false} />
                        </div>
                    </div>

                    <AppointmentModal
                        show={show}
                        setShow={setShow}
                        mode={"edit"}
                        module={"patient"}
                        inputData={patient}
                        selectedAppointment={appointment}
                        getAppointments={getAppointmentData}
                    />
                </div>
            )}
        </div>
    )
}

export default AppointmentDetails;