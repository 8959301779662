import { AgGridReact } from "ag-grid-react";
import { Button, message, Modal, Spin } from "antd";
import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import addIcon from "../../../../assets/add.png";
import closeIcon from "../../../../assets/close.png";
import deleteIcon from "../../../../assets/delete.png";
import editIcon from "../../../../assets/edit.png";
import infoIcon from "../../../../assets/info.png";
import factCheckIcon from "../../../../assets/fact-check.png";
import denialIcon from "../../../../assets/denial.png";
import openNew from "../../../../assets/open_in_new.png";
import API_BASE_URL from "../../../../env";
import { routes_patient_details } from "../../../../utils/RoutingConsts";
import CellWithOverflowTooltip from "../../../CellWithOverflowTooltip/CellWithOverflowTooltip";
import { Helmet } from "react-helmet";

const EligibilityCheck = () => {
    const Token = localStorage.getItem("Token");
    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const navigate = useNavigate();
    const { patientId } = useParams();
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [tableData, setTableData] = useState();
    const [verificationId, setVerificationId] = useState();

    const config = {
        headers: {
            Authorization: `Bearer ${Token}`
        }
    }

    useEffect(() => {
        getEligibilityCheckData();
    }, [patientId])

    const getEligibilityCheckData = () => {
        axios
            .get(`${API_BASE_URL}/api/v1/insurance_eligibility_check/getAllInsuranceEligibilityCheckDetails?patientId=${patientId}`, config)
            .then((res) => {
                setTableData(res.data.data[0]?.activeCoverage || []);
            })
            .catch(err => message.error(err.response.data.data.errors))
    }

    const columnDefs = [
        {
            field: "patientMemberId",
            headerName: "VERIFICATION ID",
            cellRenderer: (params) => {
                const { patientMemberId } = params.data;
                return (
                    <div className="row-data-element">
                        <Link to={`${routes_patient_details}/${patientId}/eligibility-check/${patientMemberId}`}>
                            <span className="app-id">{patientMemberId}</span>
                        </Link>
                    </div>
                );
            },
            minWidth: 220
        },
        {
            field: "insuranceType",
            headerName: "INSURANCE TYPE",
            cellRenderer: (params) => {
                const { insuranceType } = params.data;
                return (
                    <div className="row-data-element">
                        {insuranceType}
                    </div>
                );
            },
            minWidth: 220
        },
        {
            field: "purpose",
            headerName: "PURPOSE",
            cellRenderer: (params) => {
                const { serviceType } = params.data;
                return (
                    <div className="row-data-element">
                        <div className="full-name">
                            <CellWithOverflowTooltip value={serviceType} />
                        </div>
                    </div>
                );
            },
            minWidth: 220
        },
        {
            field: "appointmentID",
            headerName: "APPOINTMENT ID",
            cellRenderer: (params) => {
                const { appointmentID } = params.data;
                return (
                    <div className="row-data-element">
                        {appointmentID}
                    </div>
                );
            },
            minWidth: 220
        },
        {
            field: "actions",
            headerName: "ACTIONS",
            cellRenderer: (params) => {
                const { patientMemberId } = params.data;
                return (
                    <div className='actions'>
                        <img src={editIcon} alt="Edit" onClick={() => navigate(`${routes_patient_details}/${patientId}/edit-eligibility-check/${patientMemberId}`)} />
                        <img src={deleteIcon} alt="Delete" onClick={() => handleEligibilityDelete(patientMemberId)} />
                    </div>
                );
            },
            pinned: 'right',  // This fixes the column to the right side
            suppressMovable: true,  // Prevent this column from being draggable
            lockPosition: true,
            sortable: false, // Optional: Disable sorting if not needed
            filter: false,
            resizable: false,
            minWidth: 88,
        }
    ]

    const defaultColDef = useMemo(() => {
        return {
            flex: 2,
            resizable: true, // Enable column resizing
            autoHeight: true,
            wrapText: true
        };
    }, []);

    const rowHeight = 80;

    const rowSelection = useMemo(() => {
        return {
            mode: "multiRow",
        };
    }, []);

    const gridOptions = {
        suppressHorizontalScroll: false, // Enable horizontal scrolling
        overlayNoRowsTemplate: '<span class="custom-no-rows">No eligibility checks to display</span>',
    }

    const onFirstDataRendered = (params) => {
        params.api.paginationGoToPage(0); // Optional, but ensures the grid shows the first page
    };

    const handleEligibilityDelete = (id) => {
        setOpenDeleteModal(true);
        setVerificationId(id);
    }

    const deleteEligibilityCheck = () => {
        axios
            .delete(`${API_BASE_URL}/api/v1/insurance_eligibility_check/deleteInsuranceEligibilityByMemberOrPatientId?patientMemberId=${verificationId}&patientId=${patientId}`, config)
            .then((res) => {
                message.success('Deleted Successfully');
                setOpenDeleteModal(false);
                getEligibilityCheckData();
            })
            .catch(err => message.error('Failed to delete'))
    }

    return (
        <div className="appointments-container">
            <Helmet>
                <title>Eligibility Check</title>
            </Helmet>
            <div className="appointments-header">
                <span className="appointment-text">Eligibility Check</span>
                <div className="add-appointment" onClick={() => navigate(`${routes_patient_details}/${patientId}/add-eligibility-check`)}>
                    <img src={addIcon} alt="Add" className="add-icon" />
                    <span className="add-text">Eligibility Check</span>
                </div>
            </div>
            <div className="appointment-list">
                {loading && (
                    <Spin className="spinner" />
                )}

                {!loading && appointments && (
                    <div style={gridStyle} className="ag-theme-quartz">
                        <AgGridReact
                            ref={gridRef}
                            rowData={tableData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            onFirstDataRendered={onFirstDataRendered}
                            tooltipShowDelay={0}
                            rowSelection={rowSelection}
                            rowHeight={rowHeight}
                            gridOptions={gridOptions}
                        />
                    </div>
                )}

            </div>

            <Modal
                open={openDeleteModal}
                centered
                closable={false}
                footer={false}
            >
                <div className="modal-body">
                    <div className="close" onClick={() => setOpenDeleteModal(false)}><img src={closeIcon} alt="Close" /></div>
                    <div className="header">
                        <img src={infoIcon} alt="Info" className="info-icon" />
                        Delete Eligibility Check
                    </div>
                    <div className="body">
                        <span className="description">You are trying to delete a eligibility check,
                            this will remove all the data.
                            Are you sure you want to continue?
                        </span>

                    </div>
                    <div className="buttons-container">
                        <Button className="cancel" onClick={() => setOpenDeleteModal(false)}>No</Button>
                        <Button className="yes" onClick={deleteEligibilityCheck}>Yes</Button>
                    </div>
                </div>
            </Modal>

            <div className="approval-denial">
                <div className="approval">
                    <img src={factCheckIcon} alt="Fact Check" />
                    <div className="info-div">
                        <span className="info-header">Dental Crown Implant</span>
                        <span className="info-data">Eligibility approved with 80% coverage benefits(upto $1,500)</span>
                    </div>
                    <div className="info-icons">
                        <img src={closeIcon} />
                        <img src={openNew} />
                    </div>
                </div>
                <div className="denial">
                    <img src={denialIcon} alt="Fact Check" />
                    <div className="info-div">
                        <span className="info-header">Dental Cleaning</span>
                        <span className="info-data">Eligibility disapproved</span>
                    </div>
                    <div className="info-icons">
                        <img src={closeIcon} />
                        <img src={openNew} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EligibilityCheck;