export const routes_signup = "/signup";
export const routes_login = "/login";
export const routes_terms = "/terms";
export const routes_set_password = "/set-password";
export const routes_forgot_password = "/forgot-password";
export const routes_resend = "/resend";
// export const routes_dashboard = "/dashboard";

// export const routes_signup = "/signup";
// export const routes_login = "/login";
// export const routes_terms = "/terms";
// export const routes_set_password = "/set-password";
// export const routes_forgot_password = "/forgot-password";
// export const routes_resend = "/resend";
// export const routes_customers = "/dashboard/customers";
// export const routes_users = "/dashboard/users";
// export const routes_subscriptions = "/dashboard/subscriptions";
// export const routes_settings = "/dashboard/settings";
// export const customers = "/customers";
// export const users = "/users";
// export const subscriptions = "/subscriptions";
// export const settings = "/settings";

export const routes_home = "/dashboard/home";
export const routes_dashboard = "/dashboard";
export const routes_appointments = "/dashboard/appointments";
export const routes_patients = "/dashboard/patients";
export const routes_staffs = "/dashboard/staffs";
export const routes_staff_details = "/dashboard/staffs/staff-details";
export const routes_claims = "/dashboard/claims";
export const routes_clinic_Management = "/dashboard/clinic-management";
export const routes_payments = "/dashboard/payments";
export const routes_settings = "/dashboard/settings";

export const routes_patient_details = "/dashboard/patients/patient-details";

export const _dashboard = "/home";
export const _appointments = "/appointments";
export const _staffs = "/staffs";
export const _staff_details = "/staffs/staff-details/:staffId";
export const _patients = "/patients";
export const _patient_details = "/patients/patient-details/:patientId";
export const _add_eligibility_details = "/patients/patient-details/:patientId/add-eligibility-check";
export const _edit_eligibility_details = "/patients/patient-details/:patientId/edit-eligibility-check/:memberId";
export const _insurance_patient_details =
  "/patients/patient-details/:patientId/insurance-details/:recordId";
export const _patient_claim_details =
  "/patients/patient-details/:patientId/claim-details/:recordId";
export const _medical_history_details =
  "/patients/patient-details/:patientId/medical-history/:recordId";
export const _appointment_details =
  "/patients/patient-details/:patientId/appointment-details/:appointmentId";
export const _eligibility_details =
  "/patients/patient-details/:patientId/eligibility-check/:memberId";
export const _claims = "/claims";
export const _clinic_management = "/clinic-management";
export const _payments = "/payments";
export const _settings = "/settings";

export const phonecode = [
  {
    value: "+93",
    label: "+93",
  },
  {
    value: "+213",
    label: "+213",
  },
  {
    value: "+54",
    label: "+54",
  },
  {
    value: "+61",
    label: "+61",
  },
  {
    value: "+43",
    label: "+43",
  },
  {
    value: "+880",
    label: "+880",
  },
  {
    value: "+32",
    label: "+32",
  },
  {
    value: "+55",
    label: "+55",
  },
  {
    value: "+359",
    label: "+359",
  },
  {
    value: "+1",
    label: "+1",
  },
  {
    value: "+56",
    label: "+56",
  },
  {
    value: "+86",
    label: "+86",
  },
  {
    value: "+57",
    label: "+57",
  },
  {
    value: "+506",
    label: "+506",
  },
  {
    value: "+385",
    label: "+385",
  },
  {
    value: "+357",
    label: "+357",
  },
  {
    value: "+420",
    label: "+420",
  },
  {
    value: "+45",
    label: "+45",
  },
  {
    value: "+20",
    label: "+20",
  },
  {
    value: "+358",
    label: "+358",
  },
  {
    value: "+33",
    label: "+33",
  },
  {
    value: "+49",
    label: "+49",
  },
  {
    value: "+30",
    label: "+30",
  },
  {
    value: "+504",
    label: "+504",
  },
  {
    value: "+852",
    label: "+852",
  },
  {
    value: "+36",
    label: "+36",
  },
  {
    value: "+354",
    label: "+354",
  },
  {
    value: "+91",
    label: "+91",
  },
  {
    value: "+62",
    label: "+62",
  },
  {
    value: "+964",
    label: "+964",
  },
  {
    value: "+353",
    label: "+353",
  },
  {
    value: "+972",
    label: "+972",
  },
  {
    value: "+39",
    label: "+39",
  },
  {
    value: "+1-876",
    label: "+1-876",
  },
  {
    value: "+81",
    label: "+81",
  },
  {
    value: "+962",
    label: "+962",
  },
  {
    value: "+254",
    label: "+254",
  },
  {
    value: "+82",
    label: "+82",
  },
  {
    value: "+965",
    label: "+965",
  },
  {
    value: "+856",
    label: "+856",
  },
  {
    value: "+961",
    label: "+961",
  },
  {
    value: "+60",
    label: "+60",
  },
  {
    value: "+52",
    label: "+52",
  },
  {
    value: "+212",
    label: "+212",
  },
  {
    value: "+95",
    label: "+95",
  },
  {
    value: "+977",
    label: "+977",
  },
  {
    value: "+31",
    label: "+31",
  },
  {
    value: "+64",
    label: "+64",
  },
  {
    value: " +234",
    label: " +234",
  },
  {
    value: "+47",
    label: "+47",
  },
];
