import { useState, useEffect } from "react";
import { message } from "antd";
import AWS from "aws-sdk";
import { ACCESS_KEY, REGION, S3_BUCKET, SECRET_ACCESS_KEY } from "../../String";

const NavProfilePicture = ({ profile_picture_url }) => {
  const [fileContent, setFileContent] = useState("");

  useEffect(() => {
    if (profile_picture_url) {
      const s3 = new AWS.S3({
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_ACCESS_KEY,
        region: REGION,
      });

      // Get signed URL from S3
      s3.getSignedUrl(
        "getObject",
        {
          Bucket: S3_BUCKET,
          Key: profile_picture_url,
        },
        (err, url) => {
          if (err) {
            message.error("Error fetching document: " + err.message);
          } else {
            // You can directly set the file URL to the state
            setFileContent(url);
          }
        }
      );
    }
  }, [profile_picture_url]);

  return (
    <div>
      {/* Display the image once the file URL is available */}
      {fileContent && <img alt="" src={fileContent} className="profile-pic" />}
    </div>
  );
};

export default NavProfilePicture;
