// Csutomer application

// const APP_PROD_API_BASE_URL = `http://ec2-18-116-240-147.us-east-2.compute.amazonaws.com:8080`; // prod
const APP_PROD_API_BASE_URL = `https://app.ivory.ai`; // prod

// const APP_DEV_API_BASE_URL = `http://ec2-3-133-149-255.us-east-2.compute.amazonaws.com:8080`; // dev 3.147.70.174
const APP_DEV_API_BASE_URL = `https://dev.ivory.ai`; // dev

// const APP_TEST_API_BASE_URL = `http://ec2-18-191-19-60.us-east-2.compute.amazonaws.com:8080`; // test / staging
const APP_TEST_API_BASE_URL = `https://staging.ivory.ai`; // test / staging

// const API_BASE_URL = APP_PROD_API_BASE_URL; 
// const API_BASE_URL = APP_TEST_API_BASE_URL;
const API_BASE_URL = APP_DEV_API_BASE_URL;
// const API_BASE_URL = 'http://172.17.7.106:8080';

export default API_BASE_URL